import {combineReducers} from "redux";
import shippingSetting from "./shippingSettingSlice";
import returnReasonSetting from "./returnReasonSettingSlice";

const orderSettingReducer = combineReducers({
    shippingSetting,
    returnReasonSetting,

});

export default orderSettingReducer;