import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";

import CardListing from "../../../product/content/cardListing";

export const ProductsSection = (props) => {    
    const { products, productState, pageChange, onPageSizeChange, history, selectProductRef } = props;

    const [selectedProductId, setSelectedProductId] = useState([]);

    return (
        <Fragment>
            <Card className="ribbon-wrapper">
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-success">List of Products</div>
                    <CardListing
                        selectedProductIds={selectedProductId}
                        setselectedProductIds={setSelectedProductId}
                        data={products}
                        productState={productState}
                        totalCount={productState.pagination.count}
                        pageChange={pageChange}
                        onPageSizeChange={onPageSizeChange}
                        currentPage={productState.filter.currentPage}
                        history={history}
                        selectProductRef={selectProductRef}
                        hideCheckbox={true}
                    >
                    </CardListing>
                </CardBody>
            </Card>
        </Fragment>
    );
}
