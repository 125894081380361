import React, {useRef, useState} from "react";
import {Button, Col, FormGroup, Input, Label, Popover, PopoverBody} from "reactstrap";
import {Row} from "react-bootstrap";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import ModalFormSubmit from "../../../../../share-components/form/modalFormSubmit";
import {setTaxDialog} from "../../../store/draftOrderSlice";

const defaultValues = {
    is_charge: false,
    rate_name: '',
    percentage: '',
};
const schema = yup.object().shape({
    rate_name: yup.string().required('You must enter name'),
    percentage: yup.string().required('You must enter percentage')


});
const OrderTax = (props) => {
    const dispatch = useDispatch();
    const {orderSetValue, orderTrigger, orderGetValues} = props;
    const [buttonDisable, setButtonDisable] = useState(false);
    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;
    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (data.is_charge) {
            orderSetValue('tax', data);
            orderTrigger('tax');
        }else {            
            orderSetValue('tax', '');
            orderTrigger('tax');
        }
        setButtonDisable(false);
        closeDialog();

    }
    const closeDialog = () => {
        const obj = {props: {isOpen: false}, type: 'new', data: ""};
        dispatch(setTaxDialog(obj));

    }
    const orderState = useSelector(({order}) => order.draftOrder);


    return (
        <ModalFormSubmit
            defaultValues={defaultValues}
            schema={schema}
            onSubmitForm={onSubmitForm}
            reset={reset}
            formState={formState}
            handleSubmit={handleSubmit}
            title='Tax'
            selector='tax'
            isUseSelector
            dialog={orderState.taxDialogProps}
            closeDialog={closeDialog}
            buttonDisable={buttonDisable}
        >

            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Col md="12">
                            <div className="checkbox checkbox-dark">
                                <input id="is_charge" type="checkbox"  onChange={(e)=>{
                                    setValue('is_charge',e.target.checked);
                                    trigger('is_charge');

                                }} checked={getValues('is_charge')}/>
                                <Label for="is_charge">Charge Taxes {getValues('is_charge')}</Label>
                            </div>
                        </Col>

                    </FormGroup>
                </Col>
            </Row>
            {getValues('is_charge') &&<Row>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Rate Name</Label>
                        <input name="value" type="text" {...register('rate_name')}
                               className={`form-control ${errors.rate_name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.rate_name?.message}</div>
                    </FormGroup>

                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Percentage</Label>
                        <input name="value" type="number" {...register('percentage')} step="any"
                               className={`form-control ${errors.percentage ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.percentage?.message}</div>
                    </FormGroup>

                </Col>
            </Row>}
            {orderGetValues('tax') && <Row>
                <Col md={12}>
                    <button className="btn btn-danger btn-sm float-right" onClick={() => {
                        orderSetValue('tax', "");
                        orderTrigger('tax');
                        closeDialog();
                    }
                    }>Remove <i className="fa fa-trash"></i></button>
                </Col>
            </Row>}

        </ModalFormSubmit>
    );
}
export default OrderTax;