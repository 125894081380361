import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";

import ReactImageVideoLightbox from "react-image-video-lightbox";

import { formatAsPrice } from "../../../../utils/common";
import {
    resendInvoiceCollabOrder
} from "../../store/orderSlice";


export const ViewContent = (props) => {
    const { collabOrderState, orderId } = props;
    
    const dispatch = useDispatch();

    const getTitle = () => {
        let status = collabOrderState.collabOrderData.approval_status;
        let statusText = "Pending";
        if (status == 1) {
            statusText = "Approved";
        } else if (status == 2) {
            statusText = "Declined";
        }

        return statusText;
    }

    const [viewFile, setViewFile] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const initilindex = { index: 0, isOpen: false };
    const [photoIndex, setPhotoIndex] = useState(initilindex);

    useEffect(() => {
        let dataImage = [];
        if (collabOrderState.collabOrderData && collabOrderState.collabOrderData.images) {
            collabOrderState.collabOrderData.images.map((item, i) => {
                dataImage.push({
                    url: item.image_path,
                    type: "photo",
                    altTag: "some image",
                });
            });
        }

        setViewFile(dataImage);

    }, [collabOrderState.collabOrderData])

    const handlingResendInvoice = () => {
        setButtonDisabled(true);
        dispatch(resendInvoiceCollabOrder({ order_id: orderId })).then(res => {
            setButtonDisabled(false);
        });
    }

    return (
        <Fragment>
            <Row>
                <Col md="6">
                    <Card className="ribbon-wrapper">
                        <CardBody>
                            <div className="ribbon ribbon-clip h6 ribbon-dark">View Order {collabOrderState.data ? collabOrderState.data.order_name : "-"} - For {collabOrderState.data ? collabOrderState.data.customer_name : "-"}</div>

                            {collabOrderState.collabOrderData && <>
                                <div className="mb-3">
                                    <span className="form-label font-weight-bold">Status : </span>
                                    <span>{collabOrderState.collabOrderData.approval_status_name}</span>
                                </div>

                                <div className="mb-3">
                                    <span className="form-label font-weight-bold">{getTitle()} By : </span>
                                    <span>{collabOrderState.collabOrderData.approval_by_name}</span>
                                </div>

                                <div className="mb-3">
                                    <span className="form-label font-weight-bold">{getTitle()} At : </span>
                                    <span>{collabOrderState.collabOrderData.approval_at}</span>
                                </div>

                                <div>
                                    <span className="form-label font-weight-bold">Note: </span>
                                    <div dangerouslySetInnerHTML={{ __html: collabOrderState.collabOrderData.note }} />
                                </div>
                            </>}
                        </CardBody>
                    </Card>
                </Col>

                <Col md="6">
                    {(collabOrderState.collabOrderData && collabOrderState.collabOrderData.approval_status == 2 && !collabOrderState.collabOrderData.collab_order_id) && <>
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                <div className="ribbon ribbon-clip h6 ribbon-dark">Draft Order Details - {collabOrderState.collabOrderData.draft_order_name}</div>

                                <div className="mb-3">
                                    <span className="form-label font-weight-bold">Amount : </span>
                                    <span>£{formatAsPrice(collabOrderState.collabOrderData.amount)}</span>
                                </div>

                                <div className="mb-3">
                                    <span className="form-label font-weight-bold">Status : </span>
                                    <span>
                                        {collabOrderState.collabOrderData.draft_order_status_name} 
                                    </span>
                                </div>

                                <div className="mb-3">
                                    <span className="form-label font-weight-bold">Invoice Status : </span>
                                    <span>
                                        Sent on {(new Date(collabOrderState.collabOrderData.invoice_sent_at).toLocaleDateString())} 
                                        &nbsp;at {(new Date(collabOrderState.collabOrderData.invoice_sent_at).toLocaleTimeString())} 
                                    </span>
                                    <Button color="primary" size="xs" type="button" className="ml-3" onClick={handlingResendInvoice} disabled={buttonDisabled}>
                                        {buttonDisabled && <i className="fa fa-spinner fa-spin" />} Resend Invoice
                                    </Button>
                                </div>

                                {/* <div>
                                    <span className="form-label font-weight-bold">Invoice : </span>
                                    <a target="_blank" href={collabOrderState.collabOrderData.draft_order_invoice_url}>View</a>
                                </div> */}
                            </CardBody>
                        </Card>
                    </>}

                    {(collabOrderState.collabOrderData && collabOrderState.collabOrderData.approval_status == 2 && collabOrderState.collabOrderData.collab_order_id) && <>
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                <div className="ribbon ribbon-clip h6 ribbon-dark">Collab Order Details</div>

                                <div className="mb-3">
                                    <span className="form-label font-weight-bold">Order ID : </span>
                                    <span>{collabOrderState.collabOrderData.collab_order_name}</span>
                                </div>

                                <div>
                                    <span className="form-label font-weight-bold">Financial Status : </span>
                                    <span>{collabOrderState.collabOrderData.financial_status_name}</span>
                                </div>
                            </CardBody>
                        </Card>
                    </>}
                </Col>
            </Row>


            {(collabOrderState.collabOrderData && collabOrderState.collabOrderData.images && collabOrderState.collabOrderData.images.length > 0) && <>
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip h6 ribbon-warning">Images</div>

                        <Row>
                            {collabOrderState.collabOrderData.images.map((image, i) => <Col md="2" index={i} data-id={image.id} key={i}>
                                <Card>
                                    <div className="product-box">
                                        <div className="product-img">
                                            <div className="image-crop-center" style={{ height: 200 }}>
                                                <img className="img-fluid scale-image-6" src={image.image_path} alt="" />
                                            </div>
                                            <div className="product-hover">
                                                <ul>
                                                    <li>
                                                        <Button color="default" onClick={() => {
                                                            setPhotoIndex({ ...photoIndex, index: i, isOpen: true })
                                                        }}>
                                                            <i className="icon-eye"></i>
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>)}
                        </Row>
                    </CardBody>
                </Card>
            </>}


            {photoIndex.isOpen && (
                <div style={{ height: '100vh' }}>
                    <div className="lightbox-div">
                        <ReactImageVideoLightbox
                            title={"DAd"}
                            data={viewFile}
                            startIndex={photoIndex.index}
                            showResourceCount={true}
                            onCloseCallback={() => {
                                setPhotoIndex({ ...photoIndex, isOpen: false })
                            }}
                            onNavigationCallback={(currentIndex) => {
                                setPhotoIndex({ ...photoIndex, index: currentIndex })
                            }}
                        />
                    </div>
                </div>
            )}

        </Fragment>
    );
};