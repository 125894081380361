import moment from "moment";

export function editorConfiguration(data = 150) {
    return {
        toolbar: [
            {name: 'clipboard', groups: ['clipboard', 'undo']},
            {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
            {name: 'forms', groups: ['forms']},
            {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
            {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
            {name: 'links', groups: ['links']},
            {name: 'insert', groups: ['insert']},
            {name: 'styles', groups: ['styles']},
            {name: 'colors', groups: ['colors']},
            {name: 'tools', groups: ['tools']},
            {name: 'others', groups: ['others']},
            {name: 'about', groups: ['about']},
            {name: 'document', groups: ['mode', 'document', 'doctools']},
            '/',
            '/'
        ],
        height: data,
        removeButtons: 'Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,About,Styles'
    }
};

export function getFileExtension(url) {
    const uri = url.split('?')[0]
    return uri.substring(uri.lastIndexOf("."))
}

export function getProductRibbonColor(status){
    let statuscolor=[];
    statuscolor[1]="ribbon-warning";
    statuscolor[2]="ribbon-primary";
    statuscolor[3]="ribbon-success";
    statuscolor[4]="ribbon-info";
    statuscolor[5]="ribbon-danger";
    return statuscolor[status];
}
export function numberWithCommas(x) {
    x= x?x.toFixed(2):"";
    return x? "£ "+x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"£ 0.00";
}

export function dateFormat(date) {
   return   moment(date).format('YYYY-MM-DD')
}
export function convertDateFormat(date){
    return date?new Date(date):""
}
export const  logos=[
    {domain:"localhost",mainIcon:'logo-full-city-goddess.png',smallIcon:'small-tradegala.png'},
    {domain:"cgqa.mycitygoddess.com",mainIcon:'logo-full-city-goddess.png',smallIcon:'cg-logo.png'},
    {domain:"cg.mycitygoddess.com",mainIcon:'logo-full-city-goddess.png',smallIcon:'cg-logo.png'},
    {domain:"tg.tradegala.net",mainIcon:'main-tradegala.png',smallIcon:'small-tradegala.png'},
    {domain:"tgqa.tradegala.net",mainIcon:'main-tradegala.png',smallIcon:'small-tradegala.png'},
    {domain:"mpqa.mygoddiva.com",mainIcon:'goddiva-main.png',smallIcon:'goddiva-small.png'},
    {domain:"mp.mygoddiva.com",mainIcon:'goddiva-main.png',smallIcon:'goddiva-small.png'},
    {domain:"jtqa.mycitygoddess.com",mainIcon:'jerseytex-full.png',smallIcon:'jerseytex-small.png'},

];

export function getDefaultSeller(){
    return JSON.parse(localStorage.getItem('seller'));
    
}
export function optionDefaultValue(options,optionDefaultValue){
    return options.find(option => option.name === optionDefaultValue);;
}
export function downloadBlobAsXLSX(blob, fileName) {



    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.download = fileName;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download by simulating a click event
    link.click();

    // Clean up resources
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
}

/*export function formatAsPrice(value){
    if(value){
        if(typeof value == "string"){
            console.log('value is string', value);
            value = parseFloat(value.replace(/,/g, '').replace(/[£]/g, ""));
            console.log('value is string after replace', value);
        }
        value = value.toFixed(2);
        console.log('typeof value', typeof value);
        console.log('Check 1', value);
        console.log('Check 2', parseFloat(value) * 100);
        console.log('Check 3', Math.floor(parseFloat(value) * 100));
        value = parseFloat((Math.floor(parseFloat(value) * 100) / 100).toFixed(2));
        console.log('Check 4', value);
        value = parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        console.log('Check 5', value);
    }
    return value;
}*/
export function formatAsPrice(value){
    if(value){
        if(typeof value == "string"){
            value = parseFloat(value.replace(/[^0-9.-]/g, ""));
        }
        value = Math.round(parseFloat(value) * 100) / 100;
        value = value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return value;
}

export function numericValue(value){
    if(value){
        if(typeof value == "string"){
            value = parseFloat(value.replace(/[^0-9.-]/g, ""));
        }
        return parseFloat(value);
    }
    return value;
}