import React, { Fragment, useEffect, useRef, useState } from "react";
import { Badge, Card, CardBody, Col } from "reactstrap";
import { Button } from "react-bootstrap";
import DropZone from "../../../../share-components/upload/dropzone";
import { useDispatch } from "react-redux";
import dragula from 'react-dragula';
import {
    sortProductImage,
    updateProductImageAlt
} from "../../../manage-product/store/prodcutSlice";
import {
    addImageCollabOrder,
    deleteImageCollabOrder
} from "../../store/orderSlice";
import { getFileExtension } from "../../../../utils/common";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { toast } from "react-toastify";

const Image = (props) => {
    const { productState, setValue, trigger, getValues, onConfirmSendImage, currentUser, orderId, errors } = props;
    const dispatch = useDispatch();
    let imageAltRef = useRef([]);
    const [image, setimage] = useState({ pictures: [] })
    const [imagePreloader, setImageLoader] = useState(false);
    const [isDragInit, setIsDragInit] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const [viewFile, setViewFile] = useState([]);
    const [dragulaKey, setDragulaKey] = useState(0)

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setimage({
            ...image, pictureFiles
        });
    }

    const onChangeFile = (e, error) => {
        if (error.length > 0) {
            toast.error("one or many of the uploaded file size more than 20MB for image and 100MB for video", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            if (e.length > 0) {
                let fileSizeError = false;
                e.forEach((file) => {
                    if (/^image\//.test(file.type) && file.size > 20971520) {
                        fileSizeError = true;
                    } else if (file.type == 'video/mp4' && file.size > 104857600) {
                        fileSizeError = true;
                    }
                });
                if (fileSizeError) {
                    toast.error("one or many of the uploaded file size more than 20MB for image and 100MB for video", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return;
                }

                setImageLoader(true);
                dispatch(addImageCollabOrder({
                    order_id: orderId,
                    image: e,
                })).then(res => {
                    if (!res.error) {
                        setValue('images', res.payload);
                        trigger('images');
                        setDragulaKey(dragulaKey + 1);
                        setImageLoader(false);
                    }
                    ;
                });
            }
        }
    }

    let dragulaDecoratorRight = (componentBackingInstance) => {
        if (componentBackingInstance) {
            if (!isDragInit) {
                let imageContainer = dragula([componentBackingInstance], {
                    isContainer: function (el) {
                        return false; // only elements in drake.containers will be taken into account
                    },
                    moves: function (el, source, handle, sibling) {
                        return true; // elements are always draggable by default
                    },
                    accepts: function (el, target, source, sibling) {
                        return true; // elements can be dropped in any of the `containers` by default
                    },
                    invalid: function (el, handle) {
                        return false; // don't prevent any drags from initiating by default
                    },
                });
                imageContainer.on('drop', (el, target, source, sibling) => {
                    const cardOrder = source.childNodes;
                    let imageIds = [];
                    cardOrder.forEach(item => {
                        imageIds.push(item.getAttribute('data-id'))
                    });
                    dispatch(sortProductImage({ id: getValues('id'), image_ids: imageIds }));
                });
                setIsDragInit(true);
            }
        }
    }

    const deleteImage = (item) => {
        dispatch(deleteImageCollabOrder({
            'order_id': orderId, 
            'id': item.id
        })).then(res => {
            if (!res.error) {
                setValue('images', res.payload);
                trigger('images');
                setDragulaKey(dragulaKey + 1);
                setIsDragInit(false);
            }
        });
    }

    const initilindex = { index: 0, isOpen: false };
    const [photoIndex, setPhotoIndex] = useState(initilindex);

    useEffect(() => {
        let dataImage = [];
        getValues('images').map((item, i) => {
            dataImage.push({
                url: item.image_path,
                type: getFileExtension(item.image_path) == ".mp4" ? "video" : "photo",
                altTag: "some image",
            });
        });
        setViewFile(dataImage);
    }, [getValues('images')])

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-warning ribbon-left h6 mb-5">Images</div>

                    {errors.images && <div className="text-danger mt-4">{errors.images.message}</div>}

                    <div className="form-row mb-3 mt-3">

                        <div ref={dragulaDecoratorRight} style={{ display: "contents" }} key={dragulaKey}>
                            {getValues('images').map((item, i) => <Col md={2} index={i} data-id={item.id} key={i}>
                                <Card>
                                    <div className="product-box">
                                        <div className="product-img">
                                            <div className="image-crop-center" style={{ height: 200 }}>
                                                {getFileExtension(item.image_path) == ".mp4" ?
                                                    <video height="200" width={350} autoPlay={true} muted={true}
                                                        loop={true}>
                                                        <source src={item.image_path} type="video/mp4" />
                                                    </video>
                                                    : <img className="img-fluid scale-image-6" src={item.image_path}
                                                        alt="" />}
                                            </div>
                                            <div className="product-hover">
                                                <ul>
                                                    <li>
                                                        <Button color="default" onClick={() => {
                                                            setPhotoIndex({ ...photoIndex, index: i, isOpen: true })
                                                        }}>
                                                            <i className="icon-eye"></i>
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <Button color="red" onClick={() => {
                                                            deleteImage(item)
                                                        }}>
                                                            <i className="icon-trash"></i>
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>)}
                        </div>

                        <Col md={2}>
                            <Card>
                                <div className="product-box">
                                    <div className="product-img pt-3 pb-3">
                                        {imagePreloader && <div className="loader-box">
                                            <div className="loader-2"></div>
                                        </div>}
                                        {!imagePreloader && <DropZone multiple extension="image/*" onChangeFile={onChangeFile} />}
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </div>

                    {(currentUser.modules).includes('send-image') && <div className="form-row">
                        <div className="col-4 offset-8">
                            <Button onClick={onConfirmSendImage} className="pull-right  font-weight-bold mr-2" color="success"
                                type="button">Send Image
                            </Button>
                        </div>
                    </div>}
                </CardBody>
            </Card>

            {photoIndex.isOpen && (
                <div style={{ height: '100vh' }}>
                    <div className="lightbox-div">
                        <ReactImageVideoLightbox
                            title={"DAd"}
                            data={viewFile}
                            startIndex={photoIndex.index}
                            showResourceCount={true}
                            onCloseCallback={() => {
                                setPhotoIndex({ ...photoIndex, isOpen: false })
                            }}
                            onNavigationCallback={(currentIndex) => {
                                setPhotoIndex({ ...photoIndex, index: currentIndex })
                            }}
                        />
                    </div>
                </div>
            )}

        </Fragment>
    )
};

export default Image;

