import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  FormGroup,
  Form,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  ListGroup,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Media,
  Badge,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";
import productReducer from "../../manage-product/store";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  openNewDialog,
  closeNewDialog,
  getProductSettings,
  productLookBook,
  openLookBookDialog,
  closeLookBookDialog,
  addProduct,
  setProductIndexSettings,
  getProduct,
  getProducts,
  selectProduct,
  bulkProductPublish,
  setProductFilter,
  addProductLookBook,
  setActionDialog,
  openHolidayOfferDialog,
  closeHolidayOfferDialog,
  addProductHolidayOffer,
  openImportProductDialog,
  importProductCsv,
  closeImportProductDialog,
  importProductTag,
  openImportProductTagDialog,
  closeImportProductTagDialog,
  exportProducts,
  exportMarketplaceProducts,
  exportProductsToTBOne,
  exportUSProducts,
  exportLystFeedProducts,
  exportWalmartProducts,
  exportEUProducts,
  importProductsGradeTags,
  openImportProductGradeTagDialog,
  closeImportProductGradeTagDialog,
} from "../store/prodcutSlice";
import CreateForm from "./create";
import LookBookForm from "./look-book/lookBookForm";
import HolidayOfferFrom from "./holiday-offer/holidayOfferFrom";

import CardListing from "./content/cardListing";
import { productShow } from "./edit/content/settings/calculation";
import AsyncSelect from "react-select/async";
import { mapSearchAutoSuggestion } from "../../../utils/mapper";
import {
  sellerAutoSuggestionApi,
  sellerBrandSuggestionApi,
} from "../../../services/manage-product/productService";
import PreLoader from "../../../share-components/ui/preLoader";
import {
  CheckCircle,
  Info,
  MoreHorizontal,
  Target,
  Delete,
  RefreshCcw,
  Grid,
  List,
  Music,
} from "react-feather";
import {
  arrayToStringWithComma,
  filerValues,
  getFilterValues,
  moreFilterFields,
} from "./edit/content/settings/map";
import _ from "../../../@lodash";
import Select from "react-select";

import ProductFilter from "./content/filter";
import moment from "moment";
import ProductAction from "./content/productAction";
import { getBrands } from "../store/inventorySlice";
import ImportProductForm from "./importProductForm";
import ImportProductTagForm from "./importProductTagForm";
import ImportProductGradeTagForm from "./importProductGradeTagForm";
import { toast } from "react-toastify";
import { da } from "suneditor/src/lang";

const defaultValues = {
  brand: "",
  code: "",
  name: "",
  seller: "",
};

const defaultValuesLookBook = {
  title: "",
  sub_title: "",
  heading: "",
  email: "",
  name: "",
  pdfImage: "",
  template: "",
  dispatch_date: null,
  is_pre_order: false,
  rrp_price: "",
};

const defaultValuesImportProducts = {
  seller: "",
  file: "",
};

const defaultValuesImportProductsTag = {
  file: "",
};

const defaultValuesImportProductsGradeTag = {
  file: "",
};

const defaultValuesHolidayOffer = {
  end_date: null,
  is_end_date: false,
  no_of_days: null,
};
const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 100 }),
};
const dataMenu = [
  {
    type: 0,
    text: "Products",
  },
];

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  brand: yup
    .object()
    .shape({
      id: yup.string(),
    })
    .nullable()
    .required("You must select a brand."),
  name: yup.string().required("You must enter Product Name"),
  code: yup.string().required("You must enter style/sku code"),
});

yup.addMethod(yup.string, "customValidation", function (errorMessage) {
  return this.test(`test-card-length`, errorMessage, function (value) {
    const { path, createError } = this;
    let isValid = true;
    if (this.parent.template != 1) {
      return value ? true : false;
    }
    return true;
  });
});

yup.addMethod(yup.string, "customHolidayValidation", function (errorMessage) {
  return this.test(`test-card-length`, errorMessage, function (value) {
    const { path, createError } = this;
    let isValid = true;
    if (this.parent.is_active == true && !this.parent.is_end_date) {
      return value ? true : false;
    }
    return true;
  });
});

yup.addMethod(yup.date, "customHolidayValidationDate", function (errorMessage) {
  return this.test(`test-card-length`, errorMessage, function (value) {
    const { path, createError } = this;
    let isValid = true;
    if (this.parent.is_active == true && this.parent.is_end_date == true) {
      return value ? true : false;
    }
    return true;
  });
});

const schemaHolidayOffer = yup.object().shape({
  end_date: yup
    .date()
    .customHolidayValidationDate("You must enter date")
    .nullable(),
  no_of_days: yup
    .string()
    .customHolidayValidation("You must enter days")
    .nullable(),
});

const schemaImportProducts = yup.object().shape({
  seller: yup.object().required("Select Seller").typeError("Select Seller"),
  // file: yup.string().customHolidayValidation('You must enter days').nullable(),
});

const schemaImportProductsTag = yup.object().shape({
  // file: yup.string().customHolidayValidation('You must enter days').nullable(),
});

const schemaImportProductsGradeTag = yup.object().shape({
  // file: yup.string().customHolidayValidation('You must enter days').nullable(),
});

const schemaLookBook = yup.object().shape({
  // template: yup.string().nullable(),
  title: yup.string().required("You must enter title"),
  exchange_rate: yup.string().required("You must enter exchange rate"),
  to_currency: yup
    .string()
    .required("You must select currency")
    .typeError("Please select currency"),
  // rrp_price: yup.object().shape({
  //     value: yup.string(),
  // }).nullable().min(1)
  //     .required('You must select RRP/Price.'),
  rrp_price: yup.array().min(1, "you must select a Price"),
  email: yup
    .string()
    .customValidation("You must enter email")
    .email("Must be a valid email"),
  sub_title: yup.string().customValidation("You must enter sub title"),
  heading: yup.string().customValidation("You must enter heading"),
  name: yup.string().customValidation("You must enter name"),
});
const submitTypes = ["submit", "update"];
const Product = (props) => {
  const dispatch = useDispatch();

  let history = useHistory();
  let selectAllRef = useRef();
  const [timer, setTimer] = useState(null);
  let selectProductRef = useRef([]);
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]);
  const [differentPage, setDifferentPage] = useState(
    !submitTypes.includes(history.location.state?.from)
  );
  const [brands, setBrands] = useState([]);
  const [searchSeller, setSearchSeller] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [isSeller, setIsSeller] = useState(null);
  const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const actionToggle = () => setActionDropdownOpen((prevState) => !prevState);
  const exportToggle = () => setExportDropdownOpen((prevState) => !prevState);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const productState = useSelector(({ product }) => product.product);
  const product = useSelector(selectProduct);
  const [loading, isSetLoading] = useState(
    history.location.state?.from !== "update" || product.length < 1
  );
  const [isFilterable, setIsFilterable] = useState(
    history.location.state?.from !== "update" || product.length < 1
  );

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [uploadFile, setUploadFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [productImportErrors, setProductImportErrors] = useState(null);
  const [successProductImport, setSuccessProductImport] = useState(null);
  const [notImportedCount, setNotImportedCount] = useState(null);

  const [uploadFileProductTag, setUploadFileProductTag] = useState(null);
  const [selectedFileProductTag, setSelectedFileProductTag] = useState(null);
  const [productTagImportErrors, setProductTagImportErrors] = useState(null);
  const [successProductTagImport, setSuccessProductTagImport] = useState(null);
  const [notImportedTagCount, setNotImportedTagCount] = useState(null);

  const [uploadFileProductGradeTag, setUploadFileProductGradeTag] = useState(null);
  const [selectedFileProductGradeTag, setSelectedFileProductGradeTag] = useState(null);
  const [productGradeTagImportErrors, setProductGradeTagImportErrors] = useState(null);
  const [successProductGradeTagImport, setSuccessProductGradeTagImport] = useState(null);
  const [notImportedGradeTagCount, setNotImportedGradeTagCount] = useState(null);
  const [buttonDisableProductGradeTag, setButtonDisableProductGradeTag] = useState(false);

  const authData = useSelector(({ authReducer }) => authReducer);

  const {
    handleSubmit: handleSubmitHolidayOffer,
    formState: formHolidayOffer,
    reset: resetHolidayOffer,
    register: registerHolidayOffer,
    setValue: setValueHolidayOffer,
    getValues: getValuesHolidayOffer,
    trigger: holidayOfferTrigger,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValuesHolidayOffer,
    resolver: yupResolver(schemaHolidayOffer),
  });

  const {
    handleSubmit: handleSubmitLookBook,
    formState: formLookBook,
    reset: resetLookBook,
    register: registerLookBook,
    setValue: setValueLookBook,
    getValues: getValuesLookBook,
    trigger: lookbookTrigger,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValuesLookBook,
    resolver: yupResolver(schemaLookBook),
  });

  const {
    handleSubmit: handleSubmitImportProducts,
    formState: formImportProducts,
    reset: resetImportProducts,
    register: registerImportProducts,
    setValue: setValueImportProducts,
    getValues: getValuesImportProducts,
    trigger: importProductTrigger,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValuesImportProducts,
    resolver: yupResolver(schemaImportProducts),
  });

  const {
    handleSubmit: handleSubmitImportProductsTag,
    formState: formImportProductsTag,
    reset: resetImportProductsTag,
    register: registerImportProductsTag,
    setValue: setValueImportProductsTag,
    getValues: getValuesImportProductsTag,
    trigger: importProductTagTrigger,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValuesImportProductsTag,
    resolver: yupResolver(schemaImportProductsTag),
  });

  const {
    handleSubmit: handleSubmitImportProductsGradeTag,
    formState: formImportProductsGradeTag,
    reset: resetImportProductsGradeTag,
    register: registerImportProductsGradeTag,
    setValue: setValueImportProductsGradeTag,
    getValues: getValuesImportProductsGradeTag,
    trigger: importProductGradeTagTrigger,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValuesImportProductsGradeTag,
    resolver: yupResolver(schemaImportProductsGradeTag),
  });

  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonDisableProductTag, setButtonDisableProductTag] = useState(false);
  const [templateImage, setTemplateImage] = useState([]);
  const { errors: errorsLookBook } = formLookBook;
  const { errors: errorsImportProduct } = formImportProducts;
  const { errors: errorsImportProductTag } = formImportProductsTag;
  const { errors: errorsImportProductGradeTag } = formImportProductsGradeTag;
  const { errors: errorsHolidayOffer } = formHolidayOffer;
  const getSellerBrands = (id, type = 1) => {
    dispatch(getProductSettings({ seller_id: id, type })).then((res) => {
      const payload = res.payload;
      if (!res.error)
        dispatch(
          setProductIndexSettings({
            ...productState.productIndexSettings,
            ...payload,
          })
        );
    });
  };
  useEffect(() => {
    if (differentPage) {
      dispatch(setProductFilter(filerValues(authData.defaultSeller)));
    }
  }, []);

  useEffect(() => {
    if (
      !differentPage &&
      (isFilterable ||
        (isFilterable &&
          authData.defaultSeller?.value != productState.filter.seller?.value))
    ) {
      dispatch(
        setProductFilter({
          ...productState.filter,
          seller: authData.defaultSeller,
          brand: "",
          currentPage: 0,
        })
      );
    }
  }, [authData.defaultSeller]);

  const productFilter = () => {
    if (isFilterable) {
      const filter = productState.filter;
      if (
        differentPage &&
        JSON.stringify(filter) !=
          JSON.stringify(filerValues(authData.defaultSeller))
      ) {
        setDifferentPage(false);
      } else {
        const holidayMode = productState.filter.holiday_mode_date_range;
        dispatch(
          getProducts({
            page_size: filter.pageSize,
            page: filter.currentPage + 1,
            name: filter.name,
            brand: productState.filter.brand?.value,
            status: productState.filter.status,
            sort: productState.filter.sort,
            seller_id: productState.filter.seller?.value,
            category: productState.filter.category?.id,
            sub_category: productState.filter.subCategory?.id,
            country: productState.filter.country?.id,
            season: productState.filter.season?.id,
            commodity: productState.filter.commodity?.id,
            colour: productState.filter.colour?.id,
            null_check: productState.filter.null_check?.id,
            stock: productState.filter.stock
              ? productState.filter.stock.min +
                "," +
                productState.filter.stock.max
              : "",
            price: productState.filter.price
              ? productState.filter.price.min +
                "," +
                productState.filter.price.max
              : "",
            tags: productState.filter.tags
              ? arrayToStringWithComma(productState.filter.tags).id
              : "",
            styles: productState.filter.styles
              ? arrayToStringWithComma(productState.filter.styles).id
              : "",
            compositions: productState.filter.compositions
              ? arrayToStringWithComma(productState.filter.compositions).id
              : "",
            variant_type: productState.filter.variant_type?.id,
            offer_type: productState.filter.offer_type?.id,
            tag_type: productState.filter.tag_type?.id,
            holiday_mode_date_range: holidayMode
              ? holidayMode.startDate + "," + holidayMode.endDate
              : "",
            product_group: productState.filter.product_group?.id,
          })
        ).then((res) => {
          isSetLoading(false);
        });
        setDifferentPage(false);
      }
    }
    setIsFilterable(true);
  };

  useEffect(() => {
    productFilter();
  }, [
    productState.filter.currentPage,
    productState.filter.name,
    productState.filter.brand,
    productState.filter.sort,
    productState.filter.null_check,
    productState.filter.status,
    productState.filter.seller,
    productState.filter.trigger,
    productState.filter.pageSize,
    productState.filter.price,
    productState.filter.category,
    productState.filter.subCategory,
    productState.filter.stock,
    productState.filter.tags,
    currentUser.notifications,
    productState.filter.country,
    productState.filter.commodity,
    productState.filter.season,
    productState.filter.styles,
    productState.filter.compositions,
    productState.filter.variant_type,
    productState.filter.product_group,
    productState.filter.colour,
    productState.filter.offer_type,
    productState.filter.tag_type,
    productState.filter.holiday_mode_date_range,
  ]);

  const { errors } = formState;
  const closeDialog = () => {
    setValue("brand", {});
    setValue("seller", {});
    dispatch(closeNewDialog());
  };

  const closeLookDialog = () => {
    resetLookBook(defaultValuesLookBook);
    dispatch(closeLookBookDialog());
  };

  const closeHolidayDialog = () => {
    resetHolidayOffer(defaultValuesHolidayOffer);
    dispatch(closeHolidayOfferDialog());
  };

  //This is for add product form
  const onSubmitForm = (dialog, data) => {
    setButtonDisable(true);
    console.log("Data", data);
    // alert(JSON.stringify(data));
    data.seller_brand_id = data.brand.id;
    data.seller = data.seller.value;
    delete data["brand"];
    dispatch(addProduct({ ...data })).then((res) => {
      setButtonDisable(false);
      if (!res.error) {
        const productId = res.payload.id;
        history.push("product/" + productId + "/edit");
        dispatch(closeNewDialog());
      }
    });
  };

  const onSubmitLookBookForm = (dialog, data) => {
    setButtonDisable(true);

    data.product_ids = selectedProductId;
    // data.more_option = Object.values(data.more_option);
    let more_option = [];
    let priceList = [];
    data.more_option &&
      data.more_option.map((item, i) => {
        more_option.push(item.value);
      });
    data.more_option = more_option;

    data.rrp_price &&
      data.rrp_price.map((item, i) => {
        priceList.push(item.value);
      });
    data.rrp_price = priceList;

    data.dispatch_date = data.dispatch_date
      ? moment(data.dispatch_date).format("YYYY-MM-DD")
      : null;
    // data.rrp_price = data.rrp_price ? data.rrp_price.value : 'rrp';

    dispatch(addProductLookBook({ ...data })).then((res) => {
      setTimeout(() => {
        dispatch(closeLookBookDialog());
        setButtonDisable(false);
        window.open(
          `/product/look-book/product-look-book/${res.payload.id}`,
          "_blank"
        );
      }, 3000);
    });
  };

  const onSubmitImportProduct = (data) => {
    if (selectedFile) {
      setButtonDisable(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("seller_id", data.seller ? data.seller.value : "");
      dispatch(importProductCsv(formData)).then((res) => {
        if (res.payload.errors != undefined) {
          setButtonDisable(false);
          setProductImportErrors(res.payload.errors);
          setSuccessProductImport(res.payload.successProductImport);
          setNotImportedCount(res.payload.notImportedCount);
        } else {
          dispatch(closeImportProductDialog());
        }
      });
    } else {
      toast.error("CSV file is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const closeImportProduct = (data) => {
    resetImportProducts(defaultValuesImportProducts);
    setIsSeller(null);
    setUploadFile(null);
    setSelectedFile(null);
    dispatch(closeImportProductDialog());
  };

  const handleFileInputChange = (event) => {
    setUploadFile(event);
    setSelectedFile(event[0]);
  };

  const submitBtnSaveRef = (data) => {};


  const onSubmitImportProductTag = (data) => {
    if (selectedFileProductTag) {
      setButtonDisableProductTag(true);
      const formData = new FormData();
      formData.append("file", selectedFileProductTag);
      dispatch(importProductTag(formData)).then((res) => {        
        if (res.payload.is_valid) {
          closeImportProductTagModal();

          toast.success(res.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else {          
          toast.error(res.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          
          setButtonDisableProductTag(false);
          setProductTagImportErrors(res.payload.errors);
          setSuccessProductTagImport(res.payload.successProductImport);
          setNotImportedTagCount(res.payload.notImportedCount);

          const url = res.payload.error_file_url;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', '');
          document.body.appendChild(link);
          link.click();
          link.remove();          
        }

      });

    } else {
      toast.error("CSV / Excel file is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const closeImportProductTagModal = () => {
    resetImportProductsTag(defaultValuesImportProductsTag);
    setUploadFileProductTag(null);
    setSelectedFileProductTag(null);    

    setButtonDisableProductTag(false);
    setProductTagImportErrors(null);
    setSuccessProductTagImport(null);
    setNotImportedTagCount(null); 

    dispatch(closeImportProductTagDialog());
  };

  const handleFileInputChangeProductTag = (event) => {
    setUploadFileProductTag(event);
    setSelectedFileProductTag(event[0]);
  };

  const pageChange = (event) => {
    dispatch(
      setProductFilter({ ...productState.filter, currentPage: event.selected })
    );
  };
  const sellerPromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)));
      });
    });

  const typePromiseOption = (inputValue, seller) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
        seller_id: seller,
        check_seller_active: true,
      };
      setTimeout(() => {
        resolve(mapSearchAutoSuggestion(sellerBrandSuggestionApi(data)));
      });
    });

  const selectAllOnChange = (e) => {
    selectProductChange(e.target.checked);
  };

  const selectProductChange = (isChecked) => {
    const selectedProductArray = [...selectedProductId];
    const selectedPages = [...selectedPage];
    const current = productState.filter.currentPage;
    product.map(function (item, key) {
      // selectProductRef.current[item.id].checked = e.target.checked;
      let productIndex = selectedProductArray.findIndex(
        (aItem) => aItem === item.id
      );
      if (productIndex != -1) {
        if (!isChecked) {
          selectedProductArray.splice(productIndex, 1);
        }
      } else {
        selectedProductArray.push(item.id);
      }
    });
    let pageIndex = selectedPages.findIndex((aItem) => aItem === current);
    if (isChecked) {
      if (pageIndex == -1) {
        selectedPages.push(current);
      }
    } else {
      if (pageIndex == -1) {
        selectedPages.splice(pageIndex, 1);
      }
    }
    setSelectedPage(selectedPages);
    setSelectedProductId(selectedProductArray);
  };

  useEffect(() => {
    if (selectAllRef.current) {
      const current = productState.filter.currentPage;
      if (selectAllRef.current.checked) {
        if (!selectedPage.includes(current)) {
          selectAllRef.current.checked = false;
        }
      } else {
        if (selectedPage.includes(current)) {
          selectAllRef.current.checked = true;
        }
      }
    }
  }, [product]);

  const bulkProductAction = (type) => {
    dispatch(
      bulkProductPublish({ product_ids: selectedProductId, type: type })
    ).then((res) => {
      setSelectedProductId([]);
      if (type != 1) {
        dispatch(
          setProductFilter({
            ...productState.filter,
            trigger: !productState.filter.trigger,
            currentPage: 0,
          })
        );
      }
    });
  };

  const LayoutView = (layoutColumns) => {
    dispatch(
      setProductFilter({ ...productState.filter, gridColumnNo: layoutColumns })
    );
  };

  const setStatus = (statusValue) => {
    dispatch(
      setProductFilter({
        ...productState.filter,
        status: statusValue,
        currentPage: 0,
      })
    );
  };
  const onPageSizeChange = (e) => {
    setSelectedPage([]);
    dispatch(
      setProductFilter({
        ...productState.filter,
        currentPage: 0,
        pageSize: e.target.value,
      })
    );
  };
  const clearFilter = () => {
    let productField = { ...productState.filter };
    moreFilterFields().map(function (item, key) {
      productField[item.key] = "";
    });
    dispatch(setProductFilter(productField));
  };
  const filterState = productState.filter;

  const clearSelectAll = () => {
    setSelectedPage([]);
    setSelectedProductId([]);
  };

  const importProducts = (type) => {
    setBtnLoader(true);
    const data = {
      seller: productState.filter.seller?.value,
      brand: productState.filter.brand?.value,
    };
    const outputFilename = `products_us_${moment().format("YYYY-MM-DD")}.csv`;
    dispatch(exportProducts({ ...data })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setBtnLoader(false);
    });
  };

  const exportEUProductsHandle = () => {
    setBtnLoader(true);
    const data = {
      seller: productState.filter.seller?.value,
      brand: productState.filter.brand?.value,
    };
    const outputFilename = `products_eu_${moment().format("YYYY-MM-DD")}.csv`;
    dispatch(exportEUProducts({ ...data })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setBtnLoader(false);
    });
  };

  const exportMarketplaceProductsClick = () => {
    setBtnLoader(true);
    const data = {
      seller: productState.filter.seller?.value,
      brand: productState.filter.brand?.value,
    };
    const outputFilename = `products_${moment().format("YYYY-MM-DD")}.csv`;
    dispatch(exportMarketplaceProducts({ ...data })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setBtnLoader(false);
    });
  };

  const exportWalmartProductsClick = () => {
    setBtnLoader(true);
    const data = {
      seller: productState.filter.seller?.value,
      brand: productState.filter.brand?.value,
    };
    const outputFilename = `Walmart_Products_${moment().format(
      "YYYY-MM-DD"
    )}.csv`;
    dispatch(exportWalmartProducts({ ...data })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setBtnLoader(false);
    });
  };

  const exportUSProductsInventory = () => {
    setBtnLoader(true);
    const data = {
      name: productState.filter.name,
      brand: productState.filter.brand?.value,
      seller: productState.filter.seller?.value,
    };
    const outputFilename = `US_Products_Inventory_${new Date()
      .toJSON()
      .slice(0, 10)}.csv`;
    dispatch(exportUSProducts({ ...data })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setBtnLoader(false);
    });
  };

  const exportProductsToTBOneClick = () => {
    setBtnLoader(true);
    const holidayMode = productState.filter.holiday_mode_date_range;
    const data = {
      name: productState.filter.name,
      brand: productState.filter.brand?.value,
      ids: selectedProductId,
      category: productState.filter.category?.id,
      sub_category: productState.filter.subCategory?.id,
      offer_type: productState.filter.offer_type?.id,
      status: productState.filter.status,
      country: productState.filter.country?.id,
      season: productState.filter.season?.id,
      commodity: productState.filter.commodity?.id,
      colour: productState.filter.colour?.id,
      null_check: productState.filter.null_check?.id,
      stock: productState.filter.stock
        ? productState.filter.stock.min + "," + productState.filter.stock.max
        : "",
      price: productState.filter.price
        ? productState.filter.price.min + "," + productState.filter.price.max
        : "",
      tags: productState.filter.tags
        ? arrayToStringWithComma(productState.filter.tags).id
        : "",
      styles: productState.filter.styles
        ? arrayToStringWithComma(productState.filter.styles).id
        : "",
      compositions: productState.filter.compositions
        ? arrayToStringWithComma(productState.filter.compositions).id
        : "",
      variant_type: productState.filter.variant_type?.id,
      tag_type: productState.filter.tag_type?.id,
      holiday_mode_date_range: holidayMode
        ? holidayMode.startDate + "," + holidayMode.endDate
        : "",
      product_group: productState.filter.product_group?.id,
    };
    const date = moment().format("YYYYMMDD_HHmmss");
    const outputFilename = `PANDA_${date}.csv`;
    dispatch(exportProductsToTBOne(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setBtnLoader(false);
    });
  };

  const exportLystFeedProductsClick = () => {
    setBtnLoader(true);
    const data = {
      name: productState.filter.name,
      brand: productState.filter.brand?.value,
      seller: productState.filter.seller?.value,
    };
    
    dispatch(exportLystFeedProducts({ ...data })).then((res) => {
      const link = document.createElement("a");
      link.href = res.payload;
      link.setAttribute("download", '');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setBtnLoader(false);
    });
  };

  // useEffect(() => {
  //     const data = {
  //         value: '',
  //         seller_id: searchSeller
  //     }
  //     dispatch(getBrands(data)).then(res=>{
  //         setBrands(res.payload);
  //     });
  // },[dispatch, searchSeller])

  const onSubmitHolidayOfferForm = (dialog, data) => {
    setButtonDisable(true);
    let end_date = data.end_date;
    if (!data.is_end_date) {
      var someDate = new Date();
      var numberOfDaysToAdd = data.no_of_days ? parseInt(data.no_of_days) : 0;
      if (numberOfDaysToAdd > 0) {
        end_date = new Date(
          someDate.setDate(someDate.getDate() + numberOfDaysToAdd)
        );
      }
    }

    let more_option = [];
    data.product_id = selectedProductId;
    data.end_date = end_date ? moment(end_date).format("YYYY-MM-DD") : null;
    data.name = productState.filter.name;
    data.brand = productState.filter.brand?.value;
    data.status = productState.filter.status;
    data.sort = productState.filter.sort;
    data.seller_id = productState.filter.seller?.value;
    data.category = productState.filter.category?.id;
    data.sub_category = productState.filter.subCategory?.id;
    data.country = productState.filter.country?.id;
    data.season = productState.filter.season?.id;
    data.commodity = productState.filter.commodity?.id;
    data.colour = productState.filter.colour?.id;
    data.null_check = productState.filter.null_check?.id;
    data.stock = productState.filter.stock
      ? productState.filter.stock.min + "," + productState.filter.stock.max
      : "";
    data.price = productState.filter.price
      ? productState.filter.price.min + "," + productState.filter.price.max
      : "";
    data.tags = productState.filter.tags
      ? arrayToStringWithComma(productState.filter.tags).id
      : "";
    data.styles = productState.filter.styles
      ? arrayToStringWithComma(productState.filter.styles).id
      : "";
    data.compositions = productState.filter.compositions
      ? arrayToStringWithComma(productState.filter.compositions).id
      : "";
    data.variant_type = productState.filter.variant_type?.id;
    data.offer_type = productState.filter.offer_type?.id;
    data.tag_type = productState.filter.tag_type?.id;
    // data.product_group = productState.filter.product_group ?.id,
    dispatch(addProductHolidayOffer({ ...data })).then((res) => {
      setTimeout(() => {
        dispatch(closeHolidayOfferDialog());
        setButtonDisable(false);
      }, 3000);
    });
  };

  const onSubmitImportProductGradeTag = (data) => {
    if (selectedFileProductGradeTag) {
      setButtonDisableProductGradeTag(true);
      const formData = new FormData();
      formData.append("file", selectedFileProductGradeTag);
      dispatch(importProductsGradeTags(formData)).then((res) => {        
        if (res.payload.is_valid) {
          closeImportProductGradeTagModal();

          toast.success(res.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else {          
          toast.error(res.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          
          setButtonDisableProductGradeTag(false);
          setProductGradeTagImportErrors(res.payload.errors);
          setSuccessProductGradeTagImport(res.payload.successProductImport);
          setNotImportedGradeTagCount(res.payload.notImportedCount);

          if (res.payload.error_file_url) {
            const url = res.payload.error_file_url;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            link.remove(); 
          }        
        }

      });

    } else {
      toast.error("CSV / Excel file is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };  

  const closeImportProductGradeTagModal = () => {
    resetImportProductsGradeTag(defaultValuesImportProductsTag);
    setUploadFileProductGradeTag(null);
    setSelectedFileProductGradeTag(null);    

    setButtonDisableProductGradeTag(false);
    setProductGradeTagImportErrors(null);
    setSuccessProductGradeTagImport(null);
    setNotImportedGradeTagCount(null); 

    dispatch(closeImportProductGradeTagDialog());
  };

  const handleFileInputChangeProductGradeTag = (event) => {
    setUploadFileProductGradeTag(event);
    setSelectedFileProductGradeTag(event[0]);
  };

  return (
    <Fragment>
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <Breadcrumb title="Products" data={dataMenu} />
          <Container fluid={true} className="product-wrapper">
            <div className="product-grid">
              <div className="feature-products">
                {currentUser.permission.includes("Create Product") && (
                  <Row>
                    <Col sm="12">
                      <Card>
                        <Row>
                          <Col sm="6">
                            <div className="p-3">
                              <div
                                className="flex"
                                style={{ display: "inline-flex" }}
                              >
                                <a
                                  onClick={() => {
                                    selectAllRef.current.click();
                                  }}
                                  className="btn btn-outline-primary text-left btn-square"
                                >
                                  <input
                                    style={{ pointerEvents: "none" }}
                                    onChange={selectAllOnChange}
                                    ref={(ref) => {
                                      selectAllRef.current = ref;
                                    }}
                                    type="checkbox"
                                  />{" "}
                                  Select All ({selectedProductId.length})
                                </a>

                                <ButtonDropdown
                                  isOpen={actionDropdownOpen}
                                  toggle={actionToggle}
                                >
                                  <DropdownToggle
                                    caret
                                    color="primary"
                                    className="btn btn-primary btn-square"
                                  >
                                    Action
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {selectedProductId.length > 0 && (
                                      <div>
                                        {productState.filter.status == 2 && (
                                          <DropdownItem
                                            onClick={() => {
                                              bulkProductAction(1);
                                            }}
                                          >
                                            Publish
                                          </DropdownItem>
                                        )}
                                        <DropdownItem
                                          onClick={() => {
                                            bulkProductAction(2);
                                          }}
                                        >
                                          {productState.filter.status == 5
                                            ? "Unarchive"
                                            : "Archive"}
                                        </DropdownItem>
                                        {productState.filter.status == 3 && (
                                          <DropdownItem
                                            onClick={() => {
                                              let obj = {
                                                props: { isOpen: true },
                                              };
                                              dispatch(openLookBookDialog(obj));
                                            }}
                                          >
                                            Look Book
                                          </DropdownItem>
                                        )}
                                        <DropdownItem
                                          onClick={() => {
                                            getSellerBrands("", 3);
                                            let obj = {
                                              props: { isOpen: true },
                                              type: 1,
                                              title: "Sales Offer",
                                            };
                                            dispatch(setActionDialog(obj));
                                          }}
                                        >
                                          Sales Offer
                                        </DropdownItem>
                                      </div>
                                    )}
                                    <DropdownItem
                                      onClick={() => {
                                        let obj = {
                                          props: { isOpen: true },
                                          type: 1,
                                          title: "Holiday Mode",
                                          data: "",
                                        };
                                        dispatch(openHolidayOfferDialog(obj));
                                      }}
                                    >
                                      Holiday Mode
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setSuccessProductImport(null);
                                        setNotImportedCount(null);
                                        setProductImportErrors([]);
                                        dispatch(openImportProductDialog());
                                      }}
                                    >
                                      Import Products
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setSuccessProductImport(null);
                                        setNotImportedCount(null);
                                        setProductImportErrors([]);
                                        dispatch(openImportProductTagDialog());
                                      }}
                                    >
                                      Import Products Tags
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setSuccessProductGradeTagImport(null);
                                        setNotImportedGradeTagCount(null);
                                        setProductGradeTagImportErrors([]);
                                        dispatch(openImportProductGradeTagDialog());
                                      }}
                                    >
                                      Import Products Grade
                                    </DropdownItem>
                                  </DropdownMenu>
                                </ButtonDropdown>

                                <div className="grid-options d-inline-block">
                                  <ListGroup as="ul">
                                    <li>
                                      <a
                                        className="product-4-layout-view"
                                        onClick={() => LayoutView(3)}
                                      >
                                        <span className="line-grid line-grid-6 bg-primary"></span>
                                        <span className="line-grid line-grid-7 bg-primary"></span>
                                        <span className="line-grid line-grid-8 bg-primary"></span>
                                        <span className="line-grid line-grid-9 bg-primary"></span>
                                      </a>
                                    </li>
                                    <li style={{ display: "initial" }}>
                                      <a
                                        className="product-6-layout-view "
                                        onClick={() => LayoutView(2)}
                                      >
                                        <span className="line-grid line-grid-10 bg-primary"></span>
                                        <span className="line-grid line-grid-11 bg-primary"></span>
                                        <span className="line-grid line-grid-12 bg-primary"></span>
                                        <span className="line-grid line-grid-13 bg-primary"></span>
                                        <span className="line-grid line-grid-14 bg-primary"></span>
                                        <span className="line-grid line-grid-15 bg-primary"></span>
                                      </a>
                                    </li>
                                  </ListGroup>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="float-sm-right p-3">
                              <a
                                onClick={() => {
                                  dispatch(openNewDialog());
                                }}
                                className="btn btn-sm btn-primary"
                              >
                                {" "}
                                <i className="fa fa-plus"></i> Add Product
                              </a>
                            </div>

                            <div className="float-sm-right p-3">
                              <ButtonDropdown
                                color="success"
                                isOpen={exportDropdownOpen}
                                className={`btn-success ${
                                  btnLoader ? " disabled" : ""
                                }`}
                                toggle={exportToggle}
                              >
                                <DropdownToggle
                                  caret
                                  color="success"
                                  className="btn btn-success btn-square"
                                >
                                  {btnLoader ? ( // Show the spinner if loading state is true
                                    <i className="fa fa-spinner fa-spin fa-lg spinner mr-1" />
                                  ) : (
                                    "Export Products" // Show the regular text if loading state is false
                                  )}
                                </DropdownToggle>
                                {!btnLoader && (
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => {
                                        exportMarketplaceProductsClick();
                                      }}
                                    >
                                      Market Place Products
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        importProducts(2);
                                      }}
                                    >
                                      USA Products
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        exportUSProductsInventory();
                                      }}
                                    >
                                      US Products Inventory
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        exportEUProductsHandle();
                                      }}
                                    >
                                      EU Products
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        exportWalmartProductsClick();
                                      }}
                                    >
                                      Walmart Products
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        exportProductsToTBOneClick();
                                      }}
                                    >
                                      TB.One Products
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        exportLystFeedProductsClick();
                                      }}
                                    >
                                      Export Lyst Feed
                                    </DropdownItem>
                                  </DropdownMenu>
                                )}
                              </ButtonDropdown>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="8" className="project-list">
                    <Nav tabs className="border-tab">
                      <NavItem>
                        <NavLink
                          className={
                            productState.filter.status === 0 ? "active" : ""
                          }
                          onClick={() => {
                            setStatus(0);
                            clearSelectAll();
                          }}
                        >
                          <Target /> ALL
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            productState.filter.status === 1 ? "active" : ""
                          }
                          onClick={() => {
                            setStatus(1);
                            clearSelectAll();
                          }}
                        >
                          <MoreHorizontal color="#f8d62b" /> Draft{" "}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            productState.filter.status === 2 ? "active" : ""
                          }
                          onClick={() => {
                            setStatus(2);
                            clearSelectAll();
                          }}
                        >
                          <Info color="#7366ff" /> Pending
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            productState.filter.status === 3 ? "active" : ""
                          }
                          onClick={() => {
                            setStatus(3);
                            clearSelectAll();
                          }}
                        >
                          <CheckCircle color="#51bb25" /> Published
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            productState.filter.status === 4 ? "active" : ""
                          }
                          onClick={() => {
                            setStatus(4);
                            clearSelectAll();
                          }}
                        >
                          <RefreshCcw color="#a927f9" />
                          Re Published
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            productState.filter.status === 5 ? "active" : ""
                          }
                          onClick={() => {
                            setStatus(5);
                            clearSelectAll();
                          }}
                        >
                          <Delete color="#dc3545" />
                          Archive
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <Col md="4" className="text-right">
                    <span className="f-w-600 m-r-5">
                      Showing Products{" "}
                      {productShow(
                        productState.filter.currentPage,
                        8,
                        productState.pagination.page_total
                      )}{" "}
                      of {productState.pagination.total} Results
                    </span>
                    <div className="select2-drpdwn-product select-options d-inline-block">
                      <select
                        defaultValue={productState.filter.sort}
                        className="form-control btn-square"
                        name="select"
                        onChange={(e) => {
                          dispatch(
                            setProductFilter({
                              ...productState.filter,
                              sort: e.target.value,
                              currentPage: 0,
                            })
                          );
                          clearSelectAll();
                        }}
                      >
                        <option value="id-desc">Created (newest first)</option>
                        <option value="id-asc">Created (oldest first)</option>
                        <option value="name-asc">A-Z</option>
                        <option value="name-desc">Z-A</option>
                        <option value="price-asc">Price Low To High</option>
                        <option value="price-desc">Price High To Low</option>
                        <option value="total_quantity-asc">
                          Quantity Low To High
                        </option>
                        <option value="total_quantity-desc">
                          Quantity High To Low
                        </option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {currentUser.role.id != 5 && (
                    <Col xl={3} sm={12}>
                      <AsyncSelect
                        className="select2-filter"
                        isClearable={true}
                        menuPortalTarget={document.body}
                        defaultOptions
                        placeholder="select Seller"
                        value={productState.filter.seller}
                        loadOptions={sellerPromiseOption}
                        styles={{
                          ...customStyles,
                          menuPortal: (base) => ({ ...base, zIndex: 100 }),
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onClear={(e) => {}}
                        onChange={(e) => {
                          dispatch(
                            setProductFilter({
                              ...productState.filter,
                              seller: e ? e : null,
                              brand: null,
                              currentPage: 0,
                            })
                          );
                          clearSelectAll();
                          setSearchSeller(e ? e.value : null);
                        }}
                      />
                    </Col>
                  )}
                  <Col xl="3" sm="12">
                    {/*<AsyncSelect*/}
                    {/*    className="basic-single"*/}
                    {/*    isClearable*/}
                    {/*    cacheOptions*/}
                    {/*    menuPortalTarget={document.body}*/}
                    {/*    styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}*/}
                    {/*    placeholder="Select Brand"*/}
                    {/*    getOptionLabel ={(option)=>option.name}*/}
                    {/*    getOptionValue ={(option)=>option.id}*/}
                    {/*    components={{*/}
                    {/*        IndicatorSeparator: () => null*/}
                    {/*    }}*/}
                    {/*    onChange={(e)=>{*/}
                    {/*        dispatch(setProductFilter({*/}
                    {/*            ...productState.filter,*/}
                    {/*            brand: e ? e : null,*/}
                    {/*            currentPage: 0*/}
                    {/*        }));*/}
                    {/*        clearSelectAll();*/}
                    {/*    }} */}
                    {/*    loadOptions={typePromiseOption}*/}
                    {/*/>*/}
                    <AsyncSelect
                      cacheOptions={false}
                      key={productState.filter.seller?.value}
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable="true"
                      defaultOptions
                      placeholder="select Brand"
                      loadOptions={(e) => {
                        return typePromiseOption(
                          e,
                          productState.filter.seller?.value
                        );
                      }}
                      value={productState.filter.brand}
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onClear={(e) => {}}
                      onChange={(e) => {
                        dispatch(
                          setProductFilter({
                            ...productState.filter,
                            brand: e ? e : null,
                            currentPage: 0,
                          })
                        );
                        clearSelectAll();
                        setSearchBrand(e ? e.value : null);
                      }}
                    />
                  </Col>
                  <Col xl={currentUser.role.id != 5 ? "4" : "7"} sm="12">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <FormGroup className="m-0">
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue={productState.filter.name}
                          placeholder="search Product Name / Style"
                          onChange={(e) => {
                            clearTimeout(timer);

                            const newTimer = setTimeout(() => {
                              dispatch(
                                setProductFilter({
                                  ...productState.filter,
                                  name: e.target.value,
                                  currentPage: 0,
                                })
                              );
                              clearSelectAll();
                            }, 500);

                            setTimer(newTimer);
                          }}
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col md={2}>
                    <div className={`product-sidebar`}>
                      <div className="filter-section">
                        <Card>
                          <CardHeader>
                            <h6
                              className="mb-0 f-w-600"
                              onClick={() => {
                                getSellerBrands("");
                                document
                                  .querySelector(".customizer-contain")
                                  .classList.add("open");
                              }}
                            >
                              Filters
                              <span className="pull-right">
                                <i className="fa fa-chevron-right toggle-data"></i>
                              </span>
                            </h6>
                          </CardHeader>
                        </Card>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      {moreFilterFields().map(
                        (item, i) =>
                          filterState[item.key] && (
                            <Badge key={i} color="light">
                              {item.name}{" "}
                              {getFilterValues(
                                item.type,
                                filterState[item.key]
                              )}
                              <a
                                className="btn-xs btn-default"
                                onClick={() => {
                                  let obj = { ...productState.filter };
                                  obj[item.key] = "";
                                  dispatch(setProductFilter(obj));
                                  clearSelectAll();
                                }}
                              >
                                <i className="fa fa-close"></i>
                              </a>
                            </Badge>
                          )
                      )}
                    </div>
                    <CardListing
                      selectedProductIds={selectedProductId}
                      setselectedProductIds={setSelectedProductId}
                      data={product}
                      productState={productState}
                      totalCount={productState.pagination.count}
                      pageChange={pageChange}
                      onPageSizeChange={onPageSizeChange}
                      currentPage={productState.filter.currentPage}
                      history={history}
                      selectProductRef={selectProductRef}
                    ></CardListing>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <div className="customizer-contain">
                <div className="customizer-header">
                  <i
                    className="icon-close"
                    onClick={() => {
                      document
                        .querySelector(".customizer-contain")
                        .classList.remove("open");
                    }}
                  ></i>
                  <h5>
                    {"Filters      "}{" "}
                    <a className="a-text-color ml-2">
                      {" "}
                      <i
                        className="fa fa-refresh"
                        title="clear filters"
                        onClick={() => {
                          clearFilter();
                          clearSelectAll();
                        }}
                      ></i>
                    </a>
                  </h5>

                  <ProductFilter
                    customStyles={customStyles}
                    clearSelectAll={clearSelectAll}
                    productState={productState}
                    currentUser={currentUser}
                  ></ProductFilter>
                </div>
              </div>
            </Row>
          </Container>
          <ModalFormSubmit
            index={1}
            defaultValues={defaultValues}
            schema={schema}
            onSubmitForm={onSubmitForm}
            reset={reset}
            formState={formState}
            handleSubmit={handleSubmit}
            title="Product"
            selector="staff"
            dialog={({ product }) => product.product.addDialog}
            closeDialog={closeDialog}
            buttonDisable={buttonDisable}
          >
            <CreateForm
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              trigger={trigger}
              currentUser={currentUser}
              brands={productState.productIndexSettings.brands}
              getValues={getValues}
              typePromiseOption={sellerPromiseOption}
              getSellerBrands={getSellerBrands}
            ></CreateForm>
          </ModalFormSubmit>

          <ModalFormSubmit
            index={3}
            defaultValues={defaultValuesHolidayOffer}
            schema={schemaHolidayOffer}
            onSubmitForm={onSubmitHolidayOfferForm}
            reset={resetHolidayOffer}
            formState={formHolidayOffer}
            handleSubmit={handleSubmitHolidayOffer}
            title="Holiday Mode"
            selector="staff"
            dialog={({ product }) => product.product.addHolidayOfferDialog}
            closeDialog={closeHolidayDialog}
            buttonDisable={buttonDisable}
          >
            <HolidayOfferFrom
              control={control}
              errors={errorsHolidayOffer}
              trigger={holidayOfferTrigger}
              register={registerHolidayOffer}
              setValue={setValueHolidayOffer}
              getValues={getValuesHolidayOffer}
            ></HolidayOfferFrom>
          </ModalFormSubmit>

          <ModalFormSubmit
            index={2}
            defaultValues={defaultValuesLookBook}
            schema={schemaLookBook}
            onSubmitForm={onSubmitLookBookForm}
            reset={resetLookBook}
            formState={formLookBook}
            handleSubmit={handleSubmitLookBook}
            title="Look Book"
            selector="staff"
            dialog={({ product }) => product.product.addLookBookDialog}
            closeDialog={closeLookDialog}
            buttonDisable={buttonDisable}
          >
            <LookBookForm
              control={control}
              errors={errorsLookBook}
              trigger={lookbookTrigger}
              register={registerLookBook}
              setValue={setValueLookBook}
              getValues={getValuesLookBook}
            ></LookBookForm>
          </ModalFormSubmit>
          <ProductAction
            setSelectedPage={setSelectedPage}
            selectAllRef={selectAllRef}
            productFilter={productFilter}
            selectedProductId={selectedProductId}
            setSelectedProductId={setSelectedProductId}
            state={productState}
          ></ProductAction>

          <ImportProductForm
            productState={productState}
            dialog={({ product }) => product.product.importProductDialog}
            sellerPromiseOption={sellerPromiseOption}
            defaultValues={defaultValuesImportProducts}
            schema={schemaImportProducts}
            onSubmitForm={onSubmitImportProduct}
            reset={resetImportProducts}
            formState={formImportProducts}
            handleSubmit={handleSubmitImportProducts}
            title="Import Products"
            selector="products"
            closeDialog={closeImportProduct}
            buttonDisable={buttonDisable}
            handleFileInputChange={handleFileInputChange}
            submitBtnSaveRef={submitBtnSaveRef}
            trigger={importProductTrigger}
            setIsSeller={setIsSeller}
            isSeller={isSeller}
            setValue={setValueImportProducts}
            uploadFile={uploadFile}
            errors={errorsImportProduct}
            productImportErrors={productImportErrors}
            successProductImport={successProductImport}
            notImportedCount={notImportedCount}
          />          

                        
          <ImportProductTagForm
            productState={productState}
            dialog={({ product }) => product.product.importProductTagDialog}
            defaultValues={defaultValuesImportProductsTag}
            schema={schemaImportProductsTag}
            onSubmitForm={onSubmitImportProductTag}
            reset={resetImportProductsTag}
            formState={formImportProductsTag}
            handleSubmit={handleSubmitImportProductsTag}
            title="Import Products Tags"
            selector="products"
            closeDialog={closeImportProductTagModal}
            buttonDisable={buttonDisableProductTag}
            handleFileInputChange={handleFileInputChangeProductTag}
            trigger={importProductTagTrigger}
            setValue={setValueImportProductsTag}
            uploadFile={uploadFileProductTag}
            errors={errorsImportProductTag}
            productImportErrors={productTagImportErrors}
            successProductImport={successProductTagImport}
            notImportedCount={notImportedTagCount}
          />
              
          <ImportProductGradeTagForm
            productState={productState}
            dialog={({ product }) => product.product.importProductGradeTagDialog}
            defaultValues={defaultValuesImportProductsGradeTag}
            schema={schemaImportProductsGradeTag}
            onSubmitForm={onSubmitImportProductGradeTag}
            reset={resetImportProductsGradeTag}
            formState={formImportProductsGradeTag}
            handleSubmit={handleSubmitImportProductsGradeTag}
            title="Import Products Grade Tags"
            selector="products"
            closeDialog={closeImportProductGradeTagModal}
            buttonDisable={buttonDisableProductGradeTag}
            handleFileInputChange={handleFileInputChangeProductGradeTag}
            trigger={importProductGradeTagTrigger}
            setValue={setValueImportProductsGradeTag}
            uploadFile={uploadFileProductGradeTag}
            errors={errorsImportProductGradeTag}
            productImportErrors={productGradeTagImportErrors}
            successProductImport={successProductGradeTagImport}
            notImportedCount={notImportedGradeTagCount}
          />
        </>
      )}
    </Fragment>
  );
};

export default withReducer([{ product: productReducer }])(Product);
