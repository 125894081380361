
import React, { Fragment, useState, useEffect } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import Switch from "react-switch";
import AsyncSelect from "react-select/async";
import TagsInput from "react-tagsinput";

const ProductColorForm = (props) => {
    const { errors, control, register, setValue, trigger, getValues, setOptions, compositionGroupPromiseOption, productColor, colorPromiseOption } = props;
    const [isChecked, setIsChecked] = useState(getValues('priority'));
    const handleChange2 = nextChecked1 => {
        nextChecked1 ? setValue('priority', true) : setValue('priority', false)
        setIsChecked(nextChecked1);
    }
    const [isParent, setIsParent] = useState(getValues('is_parent'));
    const handleChange = nextChecked => {
        nextChecked ? setValue('is_parent', nextChecked) : setValue('is_parent', false)
        setIsParent(nextChecked);
    };

    return (
        <Fragment>
            <Col md="6">
                <Row>
                    <span>Main Color</span>
                    <FormGroup style={{ marginTop: 1, marginLeft: 4 }}>
                        <Switch
                            checked={isParent}
                            onChange={handleChange}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}

                        />
                    </FormGroup>
                </Row>
            </Col>

            <Row>
                <Col>
                    <FormGroup>
                        {isParent && <Row>
                            <Col>
                                <FormGroup>
                                    <Label className="col-form-label">Main Color</Label>
                                    <AsyncSelect
                                        name="main_color"
                                        cacheOptions
                                        isClearable
                                        defaultOptions
                                        placeholder="Select Color"
                                        defaultValue={getValues('main_color')}
                                        loadOptions={colorPromiseOption}
                                        error={errors.main_color}
                                        onChange={(e) => {
                                            setValue('main_color', e)
                                            trigger('main_color');
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        className={` ${errors.main_color ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.main_color?.message}</div>
                                </FormGroup>
                            </Col>
                        </Row>}
                    </FormGroup>
                </Col>
            </Row>


            <Row>
                <Col>
                    <FormGroup>
                        {!isParent ?
                            <Label className="col-form-label">Main Color</Label>
                            : <Label className="col-form-label">Child Color</Label>}
                        <input name="name" type="text" {...register('name')}
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Netsuite ID</Label>
                        <input name="name" type="text" {...register('netsuite_id')}
                            className={`form-control ${errors.netsuite_id ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.netsuite_id?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Colour Code</Label>
                        <input name="color_code" type="text" {...register('color_code')}
                            className={`form-control ${errors.color_code ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.color_code?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{ marginTop: 8, marginLeft: 4 }}>Priority</label>
                    <FormGroup style={{ marginTop: 1, marginLeft: 4 }}>
                        <Switch
                            checked={isChecked}
                            onChange={handleChange2}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Tags</Label>
                        <TagsInput 
                            className="form-control tag-input-custom"
                            onlyUnique={true} 
                            value={getValues('tags')}
                            onChange={(value) => {
                                setValue('tags', value)
                                trigger('tags');
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

        </Fragment>
    );
}

export default ProductColorForm;
