import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table,Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,} from "reactstrap";
import React, {Fragment, useState, useEffect} from "react";
import {downloadOrderInvoice, downloadOrderPdf, unSetTrackingData} from "../../store/orderSlice";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {formatAsPrice, numericValue} from "../../../../utils/common";


export const Item = (props) => {
    const {orderState,orderId,retrieveTrackingDetails,authData}=props;
    const dispatch = useDispatch();
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const [invoiceLoadingIcon, setInvoiceLoadingIcon] = useState(false);
    let orderSubTotal = 0;
    let shippingDiscount = 0;
    const [discountsTotal, setDiscountsTotal] = useState({});
    let quantityTotal = 0;
    let vat = 0;
    //let discountsTotal = {};

    const processPDFDownload =(name)=>{
        const data={
            id:orderId,
            params: {
                name:name,
                seller:authData.defaultSeller?.value}
        }
        dispatch(downloadOrderPdf(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${orderState.data?orderState.data.order_name:"-"}_${name.replace(/\s+/g, '-')}_${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    const handlePackageSlipDownload =()=>{
        processPDFDownload("Package Slip");
    }
    const handlePickingTicketDownload =()=>{
        processPDFDownload("Picking Ticket");
    }
    const handleInvoiceDownload =()=>{
        if(invoiceLoadingIcon) return;
        setInvoiceLoadingIcon(true);
        const data={
            id:orderId,
            params: {
                seller:authData.defaultSeller?.value}
        }
        dispatch(downloadOrderInvoice(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${orderState.data?orderState.data.order_name:"-"}_Invoice_${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setInvoiceLoadingIcon(false);
        });
    }
    const noTracking =()=>{
        dispatch(unSetTrackingData());
        toast.warning("Tracking details are required to be added to this item", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    const getDiscountDetails = (discount_allocations) => {
        let result =  discount_allocations.map(allocation => {
            let discount_application = orderState.data.discount_applications[allocation.discount_application_index];

            if (discount_application.target_selection === 'entitled') {
                let value = discount_application.value_type === 'percentage' ? `${parseFloat(discount_application.value).toFixed(1).replace(/\.0$/, '')}%` : `£${allocation.amount_set.shop_money.amount}`;
                let code = discount_application.code ? ` (${discount_application.code})` : ` (${discount_application.title})`;
            
                return value + code + ' (-£'+allocation.amount+')';
            }
            return null;
        });

        const resultWithBr = result.filter(item => item !== null); // Filter out null values

        if(resultWithBr && resultWithBr.length > 0){
            resultWithBr.map(item => <>{item}<br/></>);
            return <div className="row">
                <p><strong>Discount: </strong>&nbsp;{resultWithBr}</p>
            </div>;
        }else{
            return null;
        }
    }

    const hasEntitledDiscount = (discount_allocations) => {
        return discount_allocations.some(allocation => {
            let discount_application = orderState.data.discount_applications[allocation.discount_application_index];
            return discount_application.target_selection === 'entitled';
        });
    };

    const sumDiscountAllocationAmountsCodeWise = () => {
        let updatedDiscountsTotal = {};
        if((orderState.list).length > 0){
            orderState.list.forEach((item,index)=>{
                item.discount_allocations.forEach((allocation) => {
                    let discount_application = orderState.data.discount_applications[allocation.discount_application_index];
                    if (discount_application.target_selection == 'entitled') {
                        let discount = allocation.amount / item.quantity;
                        
                        let code = (discount_application.code) ? discount_application.code : discount_application.title;

                        updatedDiscountsTotal[code] = (updatedDiscountsTotal[code] || 0) + discount;
                    }
                }, 0)
            });
        }
        return updatedDiscountsTotal;
    }

    const sumDiscountAllocationAmounts = (item) => {
        return item.discount_allocations.reduce((total, allocation) => {
            let discount_application = orderState.data.discount_applications[allocation.discount_application_index];
            if (discount_application.target_selection == 'entitled') {
                let discount = allocation.amount / item.quantity;
                return total + discount;
            }
            return total;
        }, 0);
    }

    const getPrice = (item, type) => {
        let price = parseFloat(item.price);
        let total_price = parseFloat(item.total_price_numeric);
        let discount = parseFloat(sumDiscountAllocationAmounts(item));
       
        if(hasEntitledDiscount(item.discount_allocations)){
            if(type == "subtotal"){
                let totalPrice = price - discount;
                let formattedPrice = `£${totalPrice.toFixed(2)}`;
                formattedPrice = (
                    <>
                        <span style={{ textDecoration: 'line-through' }}>£{price.toFixed(2)}</span>
                        {' '}
                        <span>{formattedPrice}</span>
                    </>
                );
                return formattedPrice;
            }else{
                return `£${(total_price - (discount * item.quantity)).toFixed(2)}`;
            }
        }else{
            if(type == "subtotal"){
                return `£${price.toFixed(2)}`;
            }else{
                return `£${total_price.toFixed(2)}`
            }
        }
    }

    function calculateShippingVAT(grossAmount, vatPercentage) {
        let vatExcluded = grossAmount / (1 + vatPercentage);
        let vatAmount = Math.round((grossAmount - vatExcluded) * 100) / 100;
    
        return vatAmount;
    }

    function getGrandTotal(quantityTotal){
        let grantTotal = (numericValue(orderState.data.total_price) - numericValue(orderState.data.shipping_charges) + parseFloat(quantityTotal * orderState.data.per_item_shipping));
        return formatAsPrice(grantTotal);
    }
    


    useEffect(() => {
        const updatedDiscountsTotal = sumDiscountAllocationAmountsCodeWise();
        setDiscountsTotal(updatedDiscountsTotal);
    }, [orderState]);

    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip h5 ribbon-dark">Items from Order {orderState.data?orderState.data.order_name:"-"}</div>
                        {orderState.data && orderState.data.risk_level !== "LOW" && (
                            orderState.data.fulfillment_status != "fulfilled" ? (
                                <div className="text-danger">
                                    <strong>Note:</strong> This order has been flagged as possibly fraudulent. Please do not process and contact customer service at <a href="mailto:info@goddiva.co.uk">info@goddiva.co.uk </a> as soon as possible.
                                </div>
                            ) : null
                        )}                        
                        {orderState.data?orderState.data.customer_name!=="-" && <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button onClick={handleInvoiceDownload} className={(invoiceLoadingIcon) ? "btn btn-success btn-block disabled" : "btn btn-success btn-block"}>{(invoiceLoadingIcon) ? (<i className="fa fa-spinner fa-spin" aria-hidden="true"></i>) : 'Invoice'}</button>
                            </div>
                            {(currentUser.permission).includes('Create Packing Slips') && <div className="p-2">
                                <button onClick={handlePackageSlipDownload} className="btn btn-danger btn-block">Packing Slip</button>
                            </div>}
                            {(currentUser.permission).includes('Create Picking Ticket') && <div className="p-2">
                                <button onClick={handlePickingTicketDownload} className="btn btn-primary btn-block">Picking Ticket</button>
                            </div>}
                            {/* <div className="p-2">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret color="primary" className="btn btn-primary">Supplier</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>Foo Action</DropdownItem>
                                    <DropdownItem>Bar Action</DropdownItem>
                                    <DropdownItem>Quo Action</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            </div> */}
                        </div>:""}

                        <div className="table-responsive">
                            <Table>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'40%' }}>Product</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'10%' }}>Status</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'10%' }}>Return Status</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'10%', textAlign: 'right' }}>Quantity</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'15%', textAlign: 'right' }}>Price</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'15%', textAlign: 'right' }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(orderState.list).length > 0?(orderState.list.map((item,index)=>{
                                    let subTotal = getPrice(item);
                                    let withoutCurrency = parseFloat(subTotal.replace(/[£]/g, "").replace(/,/g, ''));
                                    orderSubTotal += withoutCurrency;
                                    quantityTotal += item.quantity;
                                    //vat += item.unit_tax * item.quantity;
                                    vat += item.total_tax;
                                    return (<tr key={index}>
                                        <td className="col-2">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img width="40" className="img-fluid" src={item.image}/>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="row">
                                                    <p className={'font-primary'}>{item.sku}</p>
                                                </div>
                                                <div className="row">
                                                    {(() => {
                                                    if (item.status_id===1) {
                                                        return (
                                                            <i onClick={noTracking} style={{margin: 'auto', marginLeft: 0,}} className="fa fa-truck mr-1 text-primary"></i>
                                                        )
                                                    } else if (item.status_id===2) {
                                                        return (
                                                            <a onClick={() => {retrieveTrackingDetails(item.id)}} ><i className="fa fa-truck mr-1 text-success"></i></a>
                                                        )
                                                    } else {
                                                        return (
                                                            <i className="fa fa-truck mr-1 text-dark"></i>
                                                        )
                                                    }
                                                    })()}
                                                    {item.name}
                                                </div>
                                                <div className="row">
                                                    <p><strong>Brand: </strong>{item.brand}</p>
                                                </div>
                                                <div className="row">
                                                    <p><strong>Color: </strong>{item.colour}</p>
                                                </div>
                                                <div className="row">
                                                    <p><strong>Size: </strong>{item.size}</p>
                                                </div>
                                                {item.discount_allocations && item.discount_allocations.length > 0 && (
                                                    getDiscountDetails(item.discount_allocations)
                                                )}
                                            </div>

                                        </div>
                                         
                                        </td>
                                        <td><div style={{marginTop: "2rem"}}>{item.status}</div></td>
                                        <td><div style={{marginTop: "2rem"}}>{item.return_status}</div></td>
                                        <td><div style={{marginTop: "2rem", textAlign: 'right'}}>{item.quantity}</div></td>
                                        <td><div style={{marginTop: "2rem", textAlign: 'right'}}>{getPrice(item, "subtotal")}</div></td>
                                        <td><div style={{marginTop: "2rem", textAlign: 'right'}}>£{formatAsPrice(subTotal)}</div></td>
                                    </tr>)
                                    })):null
                                }
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="font-weight-bold">Sub Total:</td>
                                        <td colSpan={2}>{orderState.list.length} items (Total Quantity: {quantityTotal})</td>
                                        <td><div style={{textAlign: 'right'}}>£{formatAsPrice(orderSubTotal)}</div></td>
                                    </tr>
                                    {orderState.data && orderState.data.total_discount && orderState.data.total_discount > 0 && orderState.data.discount_applications.map((discount, index) => {

                                        if (discount.target_selection === "all") {
                                            let discountAmount = 0;
                                            let value = discount.value_type === 'percentage' ? `${parseFloat(discount.value).toFixed(1).replace(/\.0$/, '')}%` : `£${parseFloat(discount.value).toFixed(2)}`;
                
                                            if(discount.value_type == "percentage"){
                                                if(discount.target_type == "shipping_line"){
                                                    if(userState.user.role.name == "Seller"){
                                                        return null;
                                                    }
                                                    discountAmount = (parseFloat(orderState.data.shipping_charges) * parseFloat(discount.value)) / 100;
                                                    discountAmount = parseFloat(discountAmount).toFixed(2);
                                                    shippingDiscount += discountAmount;
                                                }else{
                                                    discountAmount = (orderSubTotal * parseFloat(discount.value)) / 100;
                                                    discountAmount = discountAmount.toFixed(2);
                                                }
                                                
                                            }else{
                                                discountAmount = parseFloat(discount.value).toFixed(2);
                                            }
                                            if(discountAmount > 0){
                                                return (
                                                    <tr key={index}>
                                                        <td style={{borderStyle: "none"}}></td>
                                                        <td style={{borderStyle: "none"}}></td>
                                                        <td className="font-weight-bold">{(index == 0) ? "Discount:" : ""}</td>
                                                        <td colSpan={2}>{(discount.code ) ? discount.code + ' ('+value+')': discount.title + ' ('+value+')'}
                                                        <br/>
                                                        <strong>{(discount.target_type == "shipping_line") ? (<span className='small'>(Shipping Discount)</span>) : (<span className='small'>(Order level discount)</span>)}</strong>
                                                        </td>
                                                        <td><div style={{textAlign: 'right'}}>-£{formatAsPrice(discountAmount)}</div></td>
                                                    </tr>
                                                );
                                            }else{
                                                return null;
                                            }
                                            
                                         } else {
                                            let discountCode = (discount.code) ? discount.code : discount.title;
                                            let discountValue = 0;
                                            if (discountsTotal.hasOwnProperty(discountCode)) {
                                                discountValue = discountsTotal[discountCode];
                                                discountValue = discountValue.toFixed(2);
                                            }
                                            if(discountValue > 0){
                                                return (
                                                    <tr key={index}>
                                                        <td style={{borderStyle: "none"}}></td>
                                                        <td style={{borderStyle: "none"}}></td>
                                                        <td className="font-weight-bold">{(index == 0) ? "Discount:" : ""}</td>
                                                        <td colSpan={2}>{discountCode}
                                                        <br/>
                                                        <strong><span className='small'>(Order Item specific Discount)</span></strong>
                                                        </td>
                                                        <td><div style={{textAlign: 'right'}}>-£{formatAsPrice(discountValue)}</div></td>
                                                    </tr>
                                                );
                                            }else{
                                                return null;
                                            }                                            
                                         }
                                    })}
                                    {userState.user.role.name !== "Seller" ? (
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Shipping:</td>
                                        <td colSpan={2}></td>
                                        <td><div style={{textAlign: 'right'}}>£{orderState.data?formatAsPrice(quantityTotal * orderState.data.per_item_shipping):"0.00"}</div></td>
                                    </tr>
                                    ) : '' }
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Tax:</td>
                                        <td colSpan={2}>{(orderState.data && orderState.data.tax_rate > 0) ? 'VAT ('+(orderState.data.tax_rate*100)+'%) (Included)':""} </td>
                                        <td><div style={{textAlign: 'right'}}>£{orderState.data ? formatAsPrice(vat + (calculateShippingVAT((quantityTotal * orderState.data.per_item_shipping) - shippingDiscount, orderState.data.tax_rate))):"0.00"}</div></td>
                                    </tr>
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Total:</td>
                                        <td colSpan={2}></td>
                                        <td className="font-weight-bold"><div style={{textAlign: 'right'}}>£{orderState.data?getGrandTotal(quantityTotal):""}</div></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};