import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductApi,
    addProductImageApi,
    deleteProductApi,
    getExportApi,
    getProductApi,
    getProductCreateSetting,
    getProductsApi,
    getProductSetting,
    getSubCategoryByCategoryId,
    sellerBrandSuggestionApi,
    sortProductImageApi,
    updateInventoryBulkApi, updateInventoryBulkBySkuApi,
    updateProductApi
} from "../../../services/manage-product/productService";
import {toast} from "react-toastify";
import {
    getInventoryProductsApi,
    getInventoryProductVariantsApi, updateInventoryApi
} from "../../../services/manage-product/inventoryService";


export const getInventoryProducts = createAsyncThunk('inventory/getProducts', async (data, {dispatch}) => {
    const response = await getInventoryProductsApi(data);
    dispatch(setInventoryProductPagination(response.response.pagination));
    return response.response.data;
});

export const updateInventory = createAsyncThunk('inventory/update', async (product, {dispatch, getState}) => {
    try {
        const response = await updateInventoryApi(product.variation, product.id);
        const data = await response.response;
        toast.success("Update inventory successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const getBrands = createAsyncThunk('product/brand-auto-suggestion', async (data, {dispatch,}) => {
    const response = await sellerBrandSuggestionApi(data);
    return response.response;
});


export const getExportExcel = createAsyncThunk('inventory/export-stockprice', async (data, {dispatch,}) => {
    const response = await getExportApi(data);
    return response.response;
});

export const updateInventoryBulk = createAsyncThunk('inventory/update-bulk', async (product, {dispatch, getState}) => {
    try {
        const formData = new FormData();
        formData.append(
            'file',
            product.file
        );
        const response = await updateInventoryBulkApi(formData);
        const data = await response.response;
        toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {
        toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return e;
    }
});
export const updateInventoryBulkBuSku = createAsyncThunk('inventory/update-bulk-by-sku', async (product, {dispatch, getState}) => {
    try {
        const formData = new FormData();
        formData.append(
            'file',
            product.file
        );
        const response = await updateInventoryBulkBySkuApi(formData);
        const data = await response.response;

        toast.success("updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {
        toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return e;
    }
});

const inventoryAdapter = createEntityAdapter({});

export const {selectAll: selectInventory, selectById: selectInventoryProductId} = inventoryAdapter.getSelectors(
    state => state.product.inventory
);

const inventorySlice = createSlice({
    name: 'inventories',
    initialState: inventoryAdapter.getInitialState({
        searchText: '',
        pagination: {
            count: 1,
            total: 1,
            page_total: 1
        },
        addDialog: {
            component: 'Bulk Upload',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        productVariants: []
    }),
    reducers: {
        setInventoryProductPagination: (state, action) => {
            state.pagination = action.payload
        },
        setProductVariants: (state, action) => {
            state.productVariants = action.payload
        },
        openDialog: (state, action) => {
            state.addDialog = {
                component: 'Bulk Upload',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null,
                option: action.payload.option
            };
        },
        closeDialog: (state, action) => {
            state.addDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },

    },
    extraReducers: {
        [getInventoryProducts.fulfilled]: inventoryAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {
    setInventoryProductPagination, setProductVariants, openDialog,
    closeDialog
} =
    inventorySlice.actions;

export default inventorySlice.reducer;