import API from "../utils/api";

export const getSellersApi = async (data) => {
    const response = await API.get('seller/get-all',{params:data});
    return { response };
};

export const getSellerSettingsApi = async () => {
    const response = await API.get('seller/get-settings');
    return { response };
};

export const addSellerApi = async location => {
    const response = await API.post('seller/add-seller', location);
    return { response };
};

export const getSellerApi = async id => {
    const response = await API.get(`seller/${id}/edit`);
    return { response };
};

export const updateSellerImageApi = async (data, id)  => {
    const response = await API.post(`seller/${id}/image-update`, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const updateSellerBrandApi = async (data, id)  => {
    const response = await API.post(`seller/${id}/brand-update`, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const addBannerImageApi = async (id,data) => {
    const response = await API.post(`seller/${id}/upload-banner-image`, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const deleteBannerApi = async (id,bannerImageId) => {
    const response = await API.delete(`seller/${id}/delete-banner-image/${bannerImageId}`);
    return { response };
};

export const getSellerBrandsApi = async id => {
    const response = await API.get(`seller/${id}/get-brands`);
    return { response };
};
export const deleteSellerBrandsApi = async (id,brandId) => {
    const response = await API.delete(`seller/${id}/delete-brand/${brandId}`);
    return { response };
};
export const updateSellerApi = async (data, id)  => {
    const response = await API.post(`seller/${id}/update`, data,{'content-type': 'multipart/form-data'});
    return { response };
};
export const showSellerApi = async id => {
    const response = await API.get(`seller/${id}/show`);
    return { response };
};

export const getCommissionsApi = async (data) => {
    const response = await API.get('seller/get-commissions',{params:data});
    return { response };
};

export const downloadAdminCommissionPdfExcelApi = async (data)  => {
    const response = await API.get(`seller/download-admin-commission`,{'content-type': 'application/pdf','responseType': 'blob',params:data});
    return { response };
};

export const emailAdminCommissionExportApi = async (data) => {
    const response = await API.get('seller/email-admin-commission',{params:data});
    return { response };
};

export const agentAutoSuggestionApi = async (text) => {
    const response = await API.get('seller/agent-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const getSellerProductListAPi = async (data) =>{
    const response = await API.get('seller-product', {params:data});
    return { response };
}

export const approveRequestApi = async (data) => {
    const response = await API.post('seller-product/request-to-approval',data);
    return { response };
}

export const getSellerProductListDataAPi = async (data) =>{
    const response = await API.get('seller-product/request-to-approval', {params:data});
    return { response };
}

export const createSellerProductListDataAPi = async (data) =>{
    const response = await API.post('seller-product/create', data);
    return { response };
}

export const getSellerProductSettingsApi = async () =>{
    const response = await API.get('seller-product/settings');
    return { response };
}

export const retrieveProductsApi = async (data) =>{
    const response = await API.post('seller-product/retrieve',data);
    return { response };
}

export const getSellersListApi = async () =>{
    const response = await API.get('seller-product/get-shopify-sellers');
    return { response };
}

export const getSellerListApi = async (data) => {
    const response = await API.get('seller-product/store-sellers',{params:data});
    return { response };
}

export const UpdateSellerDataApi = async data => {
    const response = await API.post('seller/reallocate-brand', data, {'content-type': 'multipart/form-data','responseType': 'json'});
    return { response };
};

export const changeStatusApi = async (id) => {
    const response = await API.put(`seller/${id}/change-status`);
    return { response };
};