import React, {Fragment, useState} from "react";
import {Card, CardBody, Col, FormGroup, Label} from "reactstrap";
import Select from "react-select";
import VariationJsx from "./variationJqx";
import {sizeMapper} from "./settings/map";
import SizeRatio from "./sizeRatioJqx";
import SizeRatioJqx from "./sizeRatioJqx";
import ColorJqx from "./colorJqx";
import ProductInfo from "./productInfo";
import {pricePercentage} from "./settings/calculation";


const Variation = (props) => {

    const {errors, register, setValue, trigger, getValues, productState,modules,productStates,currentUser} = props;
    const columnWidth=(currentUser.modules).includes('is_hide_rrp')?4:3;
    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-danger ribbon-left mb-5">Variation Configurations</div>
                    <div className='form-row mb-3'>
                        <Col md={columnWidth}>
                            <FormGroup>
                                <Label className="col-form-label">{(currentUser.modules).includes('is_hide_rrp')?"RRP":"Price"}</Label>
                                <input name="price" type="number" {...register('price')} step="any"
                                       onBlur={(e)=>{
                                           setValue('value_change',e.target.value)
                                           // if(getValues('is_third_party_seller')){
                                           //    setValue('cost_per_item_price' ,pricePercentage(e.target.value,25))
                                           //     trigger('cost_per_item_price');
                                           // }
                                           trigger('value_change');
                                       }}
                                       className={`form-control ${errors.price ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.price?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md={columnWidth}>
                            <FormGroup>
                                <Label className="col-form-label">Sale Price</Label>
                                <input name="sale_price" type="number" {...register('sale_price')} step="any"
                                       onBlur={(e)=>{
                                           setValue('value_change',e.target.value);
                                           trigger('value_change');
                                       }}
                                       className={`form-control ${errors.sale_price ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.sale_price?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md={columnWidth}>
                            <FormGroup>
                                <Label className="col-form-label">Cost Per Item Price</Label>
                                <input name="cost_per_item_price" type="number" {...register('cost_per_item_price')} step="any"
                                       className={`form-control ${errors.cost_per_item_price ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.cost_per_item_price?.message}</div>
                            </FormGroup>
                        </Col>
                        {!(currentUser.modules).includes('is_hide_rrp') &&   <Col md={columnWidth}>
                            <FormGroup>
                                <Label className="col-form-label">RRP</Label>
                                <input name="rrp" type="number" {...register('rrp')} step="any"
                                       className={`form-control ${errors.rrp ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.rrp?.message}</div>
                            </FormGroup>
                        </Col>}
                    </div>
                    <div className="form-row mb-3">
                        <Col md="3">
                            <FormGroup>
                                <Label className="col-form-label">Selling Type</Label>
                                <Select getOptionLabel={(option) => option.name}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        defaultValue={getValues('selling_type')}
                                        isMulti
                                        getOptionValue={(option) => option.id} {...register("selling_type")}
                                        onChange={(e) => {
                                            setValue('selling_type', e);
                                            trigger('selling_type');
                                        }} className={` ${errors.selling_type ? 'is-invalid' : ''}`}
                                        options={productState.selling_types}/>

                                <div className="invalid-feedback">{errors.selling_type?.message}</div>
                            </FormGroup>
                        </Col>
                        {/*<Col md="5">*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label className="col-form-label">Color</Label>*/}
                        {/*        <Select getOptionLabel={(option) => option.name}*/}
                        {/*                defaultValue={getValues('color')}*/}
                        {/*                isMulti*/}
                        {/*                getOptionValue={(option) => option.id} {...register("color")}*/}
                        {/*                onChange={(e) => {*/}
                        {/*                    setValue('color', e);*/}
                        {/*                    trigger('color');*/}
                        {/*                }} className={` ${errors.color ? 'is-invalid' : ''}`}*/}
                        {/*                options={productState.colors}/>*/}

                        {/*        <div className="invalid-feedback">{errors.color?.message}</div>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        <Col md="4">
                            <FormGroup>
                                <Label className="col-form-label">Size/Matrix</Label>
                                <Select getOptionLabel={(option) => option.size_name}
                                        defaultValue={getValues('size_configuration')}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        getOptionValue={(option) => option.id} {...register("size_configuration")}
                                        onChange={(e) => {
                                            setValue('size_configuration', e);
                                            setValue('size',sizeMapper(e.size_matrix));
                                            trigger('size_configuration');

                                        }} className={` ${errors.size_configuration ? 'is-invalid' : ''}`}
                                        options={productState.sizes}/>

                                <div className="invalid-feedback">{errors.size_configuration?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <FormGroup>
                                <Label className="col-form-label">Colours</Label>
                                <Select getOptionLabel={(option) => option.name}
                                        defaultValue={getValues('color')}
                                        menuPortalTarget={document.body}
                                        isMulti={true}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        getOptionValue={(option) => option.id}
                                        onChange={(e) => {
                                            setValue('color', e);
                                            console.log(e);
                                            trigger('color');
                                        }} className={` ${errors.color ? 'is-invalid' : ''}`}
                                        options={productState.colors}/>

                                <div className="invalid-feedback">{errors.color?.message}</div>
                            </FormGroup>
                        </Col>
                    </div>
                    {/*<ColorJqx setValue={setValue}*/}
                    {/*             trigger={trigger} productState={productState}*/}
                    {/*             getValues={getValues}*/}
                    {/*          memoData={{colour:getValues('color_value_change')}}*/}
                    {/*>*/}

                    {/*</ColorJqx>*/}
                    <div style={{color:"red"}}>{errors.color?.message}</div>

                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-success ribbon-left mb-5">Inventory </div>
                    <div className="mt-3">
                        {(getValues('selling_type').length >1 || getValues('selling_type').find(x => x.id == 2)) && <SizeRatioJqx
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        errors={errors}
                        memoData={{size:getValues('size_configuration')}}
                    ></SizeRatioJqx>}
                        <div style={{color:"red"}}>{errors.size?.message}</div>
                    </div>
                    <div className="mt-1">
                        {getValues('is_has_draft_product') &&<Col md={12}>
                            +<ProductInfo  productStates={productStates} ></ProductInfo>
                        </Col>}
                    </div>
                    <div className="mt-3">
                        {getValues('id') &&  <VariationJsx
                        getValues={getValues}
                        settingData={{color:getValues('color'),size:getValues('size'),selling_type:getValues('selling_type'),valueChange:getValues('value_change')}}
                        setValue={setValue}
                        trigger={trigger}
                        productState={productState}
                        modules={modules}
                    ></VariationJsx>}
                    </div>
                </CardBody>
            </Card>
        </Fragment>);

}
export default Variation;