import React, { Fragment, useState, useRef, useEffect } from 'react';
import {Col, FormGroup, Input, Label, Media, Row} from "reactstrap";
import DropZone from "../../../../share-components/upload/dropzone";
import Typography from '@material-ui/core/Typography';
import {mapPartnerAutoSuggestion} from "../../../../utils/mapper";
import {partnerAutoSuggestionApi} from "../../../../services/order";
import AsyncSelect from "react-select/async";
import {exportPartnerDetails} from "../../store/draftOrderSlice";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};
const ImportOrders = (props) => {
  
    const dispatch = useDispatch();
    const { errors, control, register,setValue,trigger,roles,getValues } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [imagePreloader, setImageLoader] = useState(false);
   
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [uploadFile, setUploadFile] = useState(null);
    
    const [partner, setPartner] = useState(null);
    const [importDdpFile, setImportDdpFile] = useState(false);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 30);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const onChangeFile = e => {
        setImageLoader(true);
		setUploadFile(e);
        setValue('file', e[0]);
        setImageLoader(false);
        trigger('file');

	};
    const id = partner;

    useEffect(() => {
    }, [partner]);

    const partnerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data ={
                value: inputValue
            }
            setTimeout(() =>{
                resolve(mapPartnerAutoSuggestion(partnerAutoSuggestionApi(data)))
            });
        });

        const exportPartner = () => {
            setImportDdpFile(false)
            const data = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                id
            }
            const outputFilename = `partner_${(new Date().toJSON().slice(0, 10))}.csv`;
            dispatch(exportPartnerDetails({...data})).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
            });
        }

    return (
        <Fragment>

            <FormGroup>
            <Label className="form-label">Partner</Label>
             <AsyncSelect
                name="partner"
                className={`select2-filter border ${errors.partner ? 'is-invalid' : ''}`}
                isClearable={true}
                defaultOptions
                styles={customStyles}
                placeholder="Select Partner"
                loadOptions={partnerPromiseOption}
                components={{
                    IndicatorSeparator: () => null
                }}
                onClear={(e) => {

                }}
                onChange={(e) => {
                    setPartner(e?e.value:null);
                    setValue('partner', e);
                    trigger('partner');
                }}

            />
            <div className="invalid-feedback">{errors.partner?.message}</div>
            </FormGroup>

            <FormGroup>
            <Row>
            <Col md={8}>
            <Label className="form-label">Upload Your File<span style={{color:"red"}}>*</span></Label>
            </Col>
            <Col md={4}>
            {partner && (
            <a className="btn btn-sm btn-primary"
            onClick={() => {
                exportPartner()
            }}
         > CSV Format</a>
            )}
            </Col>
            </Row>
            <Col md={12}>
                <div className="product-box">
                    <div className="product-img pt-3 pb-3">
                        {imagePreloader&& <div className="loader-box">
                            <div className="loader-2"></div>
                        </div>}
                        {!imagePreloader&& <DropZone 
                                        name="file" 
                                        extension={".csv"}
                                        className={`${errors.file ? 'is-invalid' : ''}`}
                                        onChangeFile={onChangeFile}></DropZone>}
                    </div>
                    {uploadFile ? (
								
                        <Typography>File: {`${uploadFile[0].name}`}{' '}</Typography>

                    ) : (
                        ''
                    )}
                </div>
                </Col>
                <div className="validation-msg">{errors.file?.message}</div>
            </FormGroup> 

           

        </Fragment>
    );
}

export default ImportOrders;
