import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {toast} from "react-toastify";

import {newPasswordCredentailsApi} from "../../../services/manage-user/user";
import { addPartnerApi, getPartnerApi, getPartnersApi, getPartnerSettingsApi, showPartnerApi, updatePartnerApi, updatePartnerOrderApi, updatePartnerStatusApi, partnerFormatSelectAutoSuggestionApi } from '../../../services/partner';

export const getPartners = createAsyncThunk('partners/getAllPartners', async (data,{dispatch,}) => {
    const response = await getPartnersApi(data);
    dispatch(setPartnerData(response.response));
    dispatch(setShippingSettingPaginationData(response.response.pagination))
    return response.response;
});

export const getPartner = createAsyncThunk('partners/editPartner', async (id,{dispatch})=>{
    const response = await getPartnerApi(id);
    return response.response;
});

export const showPartner = createAsyncThunk('partners/getPartner', async (id,{dispatch})=>{
    const response = await showPartnerApi(id);
    return response.response;
});

export const addPartner = createAsyncThunk('partners/addPartner', async (partner, { dispatch, getState }) => {
    try {
        const response = await addPartnerApi(partner);
        const data = await response.response;
        // dispatch(getUsers());
        toast.success("Partner added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const updatePartnerOrder = createAsyncThunk('partners/updatePartnerOrder', async (data, { dispatch }) => {
	try {
		const response = await updatePartnerOrderApi(data);
        toast.success("Order Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
		return await response.response;
	} catch (e) {
		console.log(e);
		return Promise.reject(e);
	}
});

export const updatePartner = createAsyncThunk('partners/updatePartner', async (data,{dispatch}) => {
    const response = await updatePartnerApi(data,data.id);
    toast.success(`Partner updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const updateStatus = createAsyncThunk('partners/onActiveDeactivate', async (data, { dispatch }) => {
	try {
		const response = await updatePartnerStatusApi(data);

        if (response.response.error) {
            toast.error(response.response.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }else{
            toast.success("Status Updated Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        
		return await response.response;
	} catch (e) {
		console.log(e);
		return Promise.reject(e);
	}
});

export const getPartnerSettings = createAsyncThunk('partner/getPartnerSettings', async () => {
    const response = await getPartnerSettingsApi();
    return response.response;
});

const partnersAdapter = createEntityAdapter({});

export const { selectAll: selectPartner, selectById: selectPartnerById } = partnersAdapter.getSelectors(
    state => state.partner.partner
);

const partnerSlice = createSlice({
    name: 'partners',
    initialState: partnersAdapter.getInitialState({
        id:null,
        partnerTableRow:0,
        searchText: '',
        settings:{
          countries:[],
          formats:[],
          channelId:[]
        },
        data:null,
        commissionData:null,
        status:'',
        pageCount: 0,
        
    }),
    
    reducers: {
        setTableRowCount: (state, action) => {
            state.partnerTableRow = action.payload;
        },
        setTableDataCommission: (state, action) => {
            state.commissionData = action.payload;
        },
        setSettings: (state, action) => {
            state.settings = action.payload.settings;
            state.id = action.payload.id
        },
        setPartnerData: (state, action) => {
			state.partnerData = action.payload;
		},
        setShippingSettingPaginationData: (state, action) => {
            state.pageCount = action.payload.count
        },
        setStatusVal: (state, action) => {
            state.status = action.payload
        },
        editDialog: (state, action) => {
			state.dialog = {
				component: 'Schedule',
				type: 'new',
				props: {
					open: true,
					maxWidth: 'md'
				},
				data: null
			};
        },
    },
    extraReducers: {
        [getPartners.fulfilled]: partnersAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {setTableRowCount,setTableDataCommission, setSettings, setPartnerData,editDialog, setShippingSettingPaginationData, setStatusVal } =
    partnerSlice.actions;

export default partnerSlice.reducer;
