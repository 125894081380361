import {combineReducers} from "redux";
import salesReport from "./salesReport/store/salesReportSlice";
import backInStockRequest from "./backInStockReport/store/backInReportSlice";
import exchangeOrder from "./exchangeOrder/store/exchangeOrderSlice"
import offerItemReport from "./verifyOfferItems/store/verifyOfferItemsSlice";
import invontoryReport from "./invontoryReport/store/invontoryReportSlice";

const reportReducer = combineReducers({
    salesReport,
    backInStockRequest,
    exchangeOrder,
    offerItemReport,
    invontoryReport
});

export default reportReducer;