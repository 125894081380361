import API from "../../../utils/api";


export const getBinLocationTypeDataApi = async (filters) => {
    const response = await API.get('bin-location-type/setting-data', { params: filters });
    return { response };
};

export const getAllTypeDataApi = async (filters) => {
    const response = await API.get('bin-location-type', { params: filters });
    return { response };
};

export const retrieveBinLocationTypeApi = async id => {
	const response = await API.get(`bin-location-type/${id}`);
	return { response };
};
export const addBinLocationTypeApi = async data => {
    const response = await API.post('bin-location-type', data);    
    return { response };
};

export const updateBinLocationTypeApi = async (item, id) => {
    const response = await API.put(`bin-location-type/${id}`, item);
    return { response };
};

export const deleteBinLocationTypeApi = async (id) => {
    const response = await API.delete(`bin-location-type/${id}`);
    return { response };
};
