import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Nav, Popover, PopoverBody, Row} from "reactstrap";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import binLocationTypeReducer from "../../store";
import BinLocationTypeFrom from "./binLocationTypeForm";

import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {binLocationTypeResponseToTable} from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import PreLoader from "../../../../share-components/ui/preLoader";
import { addBinLocationType, 
    closeEditDialog, deleteBinLocationType, 
    editBinLocationType, getAllBinLocationType, openEditDialog, openNewDialog, selectBinLocationType, updateBinLocationType } from "../../store/binLocationTypeSlice";



const defaultValues = {
    id: '',
    title: '',
    capacity: '',
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 2,
        text: 'Bin Location Type',
        link: ''
    }
];

const schema = yup.object().shape({
    title: yup.string().required('You must enter a type title'),
    capacity: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('You must enter a capacity'),
});

const BinLocationType = () => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState([]);
    const [tag, setTag] = useState([]);
    const binLocationTypes = useSelector(selectBinLocationType);
    const [loading, setLoading] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    useEffect(() => {
        const data = {
            title,
            // tag
        }
        dispatch(getAllBinLocationType({...data})).then(res=>{
            if (!res.error)
                setLoading(false);
        });

    }, [dispatch, title]);

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const onSubmitForm = (dialog, data) => {
        console.log('here');
        setButtonDisable(true);
             
        if (dialog.type === 'new') {
            dispatch(addBinLocationType({...data})).then(res => {
                setButtonDisable(false);
                console.log(res)
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateBinLocationType({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    };

    const closeDialog = () => {
        dispatch(closeEditDialog());
    };

    const onEdit = (row) => {
        dispatch(editBinLocationType(row.id)).then(res => {
			if (!res.error) dispatch(openEditDialog(res.payload));
		});
        // dispatch(openEditDialog(row));
    };

    const onDelete = (row) => {
        dispatch(deleteBinLocationType(row.id))
    }

    const tableColumns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            left: true,
            width:"20%"
        },
        {
            name: 'Capacity',
            selector: row => row.capacity,
            // selector: row => (row.tooltip_list? (<PopoverBasicItem id={row.id} Popoverbody={row.tooltip_list} btntext={row.capacity}/>):row.tagName),
            sortable: true,
            left: true,
            width:"60%"
        }
    ]

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                    <Breadcrumb data={dataMenu} title="Bin Location Types"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {/* {(currentUser.permission).includes('Create Product Types') ? */}
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Bin Location Type</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        {/* :''} */}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Form>
                                        <FormGroup className="m-0">

                                            <input onChange={(e) => {
                                                setTitle(e.target.value)
                                            }}
                                                   type="text"
                                                   className="form-control border"
                                                   placeholder="Search By Title"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table mt-4">
                                                <CommonDataTable
                                                    headerColumns={tableColumns}
                                                    gridData={binLocationTypeResponseToTable(binLocationTypes)}
                                                    pagination
                                                    onEdit={onEdit}
                                                    onDelete={onDelete}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                         </>
                }

            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Bin Location Type'
                selector='binLocationType'
                dialog={({product}) => product.binLocationType.addDialog}
                closeDialog={closeDialog}
                // publish
                setValue={setValue}
                getValues={getValues}
                // btnText={btnText}
                buttonDisable={buttonDisable}
            >
                <BinLocationTypeFrom  control={control} errors={errors} register={register} setValue={setValue}
                                 trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([
    {
        binLocationType: binLocationTypeReducer
    }
])(BinLocationType);