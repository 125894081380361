import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader
} from "reactstrap";


import PreLoader from "../../../share-components/ui/preLoader";
import {
    generateAllPickingTicket,
    getLatestPickingTicket,
    openPickingTicketDialogs,
    setLatestPickingTickets
} from "../store/orderSlice";

import PickingTicketPanel from './content/pickingTicketPanel';
import './orderPickingTicket.scss';


const OrderPickingTicket = (props) => {

    const { orderState } = props;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);


    const closDialog = () => {
        dispatch(openPickingTicketDialogs(false))
        setLoading(false);
    }

    useEffect(() => {
        if (orderState.pickingTicketDialogs.props.isOpen) {
            dispatch(getLatestPickingTicket()).then(res => {
                dispatch(setLatestPickingTickets(res.payload));
                setLoading(false)
            })
        }
    }, [orderState.pickingTicketDialogs.props.isOpen]);

    const generatePickingTicket = (data) => {
        setLoading(true);
        dispatch(generateAllPickingTicket(data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `picking_tickets.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                dispatch(openPickingTicketDialogs(false))
                setLoading(false);
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
            }
        });
    }


    return (
        <>
            <Modal className="modal-lg modal-dialog-centered product-modal"
                size="lg"   {...orderState.pickingTicketDialogs.props}>
                <ModalHeader toggle={() => {
                    closDialog()
                }}>
                    Picking Ticket
                </ModalHeader>
                {!loading ?
                    <>
                        <ModalBody className="py-0">
                            <div className="row picking-ticket-row">
                                {orderState.latestPickingTickets.length > 0 ? <>

                                    {orderState.latestPickingTickets.map(item => (
                                        <PickingTicketPanel
                                            key={item.id}
                                            item={item}
                                        ></PickingTicketPanel>
                                    ))}

                                </> : <>
                                    No records!
                                </>}
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <div className="float-right">
                                <Button type="button" color="primary" onClick={() => {
                                    generatePickingTicket({});
                                }}>Generate New Picking Ticket</Button>
                            </div>
                        </ModalFooter>
                    </>
                    :
                    <PreLoader withoutText></PreLoader>
                }
            </Modal>
        </>);

}
export default OrderPickingTicket;
