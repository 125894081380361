import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useRef} from "react";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";

const TrackingForm = (props) => {
    const {errors,register, orderState, setValue, getValues, trigger}=props;
    
    const userState =  useSelector(({authReducer}) => authReducer.data);

    return (
        <Fragment>
                <Row>
                    {/* <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Tracking Name<span style={{color:"red"}}>*</span></Label>
                            <input name="deliver_partner" type="text" className={`form-control ${errors.deliver_partner ? 'is-invalid' : ''}`} placeholder="DHL" {...register('deliver_partner')}  />
                            <div className="invalid-feedback">{errors.deliver_partner?.message}</div>
                        </FormGroup>
                    </Col> */}
                    <Col md={6}>
                        <Label for="selectOption">Select Delivery Partner</Label>
                            <Select 
                                placeholder="Select Partners"
                                type="select"
                                name="deliver_partner"
                                value={getValues('deliver_partner')}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                {...register("deliver_partner")}
                                isClearable
                                options={orderState.deliverPartner}
                                onChange={(e) => {
                                    setValue('deliver_partner',e);
                                    trigger('deliver_partner');
                                }} 
                                className={` ${errors.deliver_partner ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.deliver_partner?.message}</div>
                        
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Tracking ID<span style={{color:"red"}}>*</span></Label>
                            <input name="tracking_id" type="text" className={`form-control ${errors.tracking_id ? 'is-invalid' : ''}`} placeholder="2164116410" {...register('tracking_id')} />
                            <div className="invalid-feedback">{errors.tracking_id?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Label className="form-label">Tracking URL<span style={{color:"red"}}>*</span></Label>
                            <input name="tracking_url" type="text" className={`form-control ${errors.tracking_url ? 'is-invalid' : ''}`} placeholder="https://www.dhl.co.uk/url" {...register('tracking_url')} />
                            <div className="invalid-feedback">{errors.tracking_url?.message}</div>
                        </FormGroup>
                    </Col>

                    {userState.user.is_primary_seller ? 
                    <>
                        {(getValues('status') != 1) ? '' : 
                        <Col md={12}>
                            <FormGroup className="form-check">
                                <input type="checkbox" className="form-check-input" id="tackingIsUpdate" {...register('only_update')} />
                                <Label className="form-check-label" for="tackingIsUpdate">Update tracking details on Shopify</Label>
                            </FormGroup>
                        </Col>
                        }
                    </> 
                    : 
                    <></>
                    }
                </Row>
        </Fragment>
    );
};

export default TrackingForm;