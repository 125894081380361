import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DropZone from "../../../share-components/upload/dropzone";
import Typography from "@material-ui/core/Typography";


export default function ImportForm(props) {

    const {
        dialog,
        size = 'lg',
        handleSubmit,
        onSubmitForm,
        handleFileInputChange,
        submitBtnSaveRef,
        buttonDisable,
        uploadFile,
        closeDialog
    } = props;

    const importDialog = useSelector(dialog);

    const closeModalDialog = () => {
        closeDialog();
    }

    return (
        <div className="flex flex-col modal-lg">
            <Modal size={size} {...importDialog.props} toggle={() => closeModalDialog()}>
                <ModalHeader toggle={() => {
                    closeModalDialog()
                }}>
                    Import Products
                </ModalHeader>

                <Form onSubmit={handleSubmit(onSubmitForm)}>
                    <ModalBody>
                        <Row className="mt-3 mb-3">
                            <Col md={12} className="mt-4">
                                <div className="product-box">
                                    <div className="product-img pt-3 pb-3">
                                        <DropZone
                                            extension={".csv"}
                                            onChangeFile={handleFileInputChange}></DropZone>
                                    </div>
                                    {uploadFile ? (

                                        <Typography>File: {`${uploadFile[0].name}`}{' '}</Typography>

                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt-2">
                        <button
                            disabled={buttonDisable}
                            ref={submitBtnSaveRef}
                            className="btn btn-primary btn-block">
                            {buttonDisable ? <i className="fa fa-spinner fa-spin fa-lg spinner" /> : ''} Import
                        </button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    )
}