import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Form, InputGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SunRichEditor from "../../../share-components/ui/sunRichEditor";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import { useHistory, useParams } from "react-router-dom";
import orderReducer from "../store";
import Image from "./verify-collab-order/image";
import {
    setCollabOrderFilter,
    approveCollabOrder,
    getCollabOrder
} from "../store/orderSlice";
import PreLoader from "../../../share-components/ui/preLoader";
import { formatAsPrice } from "../../../utils/common";
import { Link } from 'react-router-dom';


const VerifyCollabOrder = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const collabOrderState = useSelector(({ order }) => order.order);
    const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
    const [status, setStatus] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalItemAmount, setTotalItemAmount] = useState(0);

    const schema = yup.object().shape({
        approval_status: yup.string()            
            .typeError('You must select an option')
            .required('You must select an option'),
        amount: yup.number()
            .typeError('Amount must be a number')
            .required('Amount is required')
            .min(0.01, 'Amount must be greater than 0')
            .max(totalItemAmount, `Amount must be equal to or less than ${totalItemAmount}`),
        // note: yup.string().required('You must enter Comment'),
        images: yup.array()
            .of(                
                yup.object({
                    image_path: yup.string().required('Image path is required')
                })
            )
            .when('approval_status', {
                is: '1', // If approval_status is '1'
                then: yup.array()
                    .min(1, 'Please choose at least one image'),
                otherwise: yup.array().notRequired(), // Otherwise, the images field is not required
            }),
    });
    
    const defaultValues = {
        id: '',
        approval_status: null,
        amount: 0,
        note: null,
        images: []
    };
    
    const dataMenu = [
        {
            type: 1,
            text: 'Collab Orders',
            link: '/order/collab-orders'
        },
        {
            type: 0,
            text: "Verify Order"
        }
    ];

    useEffect(() => {
        setIsLoading(true);
        if (id) {
            dispatch(getCollabOrder(id)).then(res => {
                setIsLoading(false);
                if (res.payload.collab_order.approval_status == 1 || res.payload.collab_order.approval_status == 2) {
                    onView();
                }
            });
        }

        dispatch(setCollabOrderFilter({
            ...collabOrderState.collabFilters,
            isResetFilters: true
        }));
    }, [dispatch, id]);

    const { handleSubmit, formState, reset, control, register, setValue, trigger, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { errors, isDirty } = formState;

    const radioHandler = (status) => {
        setStatus(status);
    };

    const onFormSubmit = (data) => {
        setButtonDisabled(true);
        dispatch(approveCollabOrder({ ...data, ...{ order_id: id } })).then(res => {
            setButtonDisabled(false);
            onView();
        });
    }

    useEffect(() => {
        let is_view = false;
        let images = [];
        let note = null;

        if (collabOrderState.collabOrderData) {
            if (collabOrderState.collabOrderData.approval_status == 1 || collabOrderState.collabOrderData.approval_status == 2) {
                is_view = true;
            }

            if (collabOrderState.collabOrderData.images) {
                images = collabOrderState.collabOrderData.images;
            }

            note = collabOrderState.collabOrderData.note;
        }

        if (!is_view && images.length > 0) {
            setStatus(1);
        }

        setIsView(is_view);
        setValue('note', note);
        setValue('images', images);
    }, [collabOrderState.collabOrderData])


    useEffect(() => {
        let amount = collabOrderState.data ? Number(collabOrderState.data.shipping_charges) : 0;

        if (collabOrderState.list) {
            collabOrderState.list.map((item) => {
                amount += Number(item.gross_price) * Number(item.quantity);
            });
        }

        setTotalItemAmount(amount);
        setValue('amount', amount.toFixed(2));
    }, [collabOrderState.list])

    const onView = () => {
        history.push('/order/collab-orders/' + id + '/view');
    }


    return (
        <Fragment>
            <Breadcrumb title="Verify Order" data={dataMenu} />
            <Container fluid={true}>
                {isLoading ? <>
                    <PreLoader></PreLoader>
                </> : <>
                    <Row>
                        {isView ? <>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <p>
                                            Already verified!
                                            <Link to={`/order/collab-orders/${id}/view`} className="ml-3">View</Link>
                                            </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </> : <>
                            <Col md="12">
                                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onFormSubmit)}>
                                    <Card>
                                        <CardBody>
                                            <div className="form-row">
                                                <Col md="12">
                                                    <FormGroup>
                                                        <Label className="form-label">Did the Social Media Influencer created and uploaded content on the social platforms?<span style={{ color: "red" }}>*</span></Label>
                                                        <InputGroup className={`m-t-15 m-checkbox-inline pl-2 ${errors.approval_status ? 'is-invalid' : ''}`}>
                                                            <div className="radio radio-primary">
                                                                <input id="radioinline1" type="radio" name="approval_status" value="1" checked={status === 1}
                                                                    onClick={(e) => radioHandler(1)}
                                                                    {...register('approval_status')} />
                                                                <Label className="mb-0 text-success" for="radioinline1">Approve</Label>
                                                            </div>
                                                            <div className="radio radio-primary">
                                                                <input id="radioinline2" type="radio" name="approval_status" value="2" checked={status === 2}
                                                                    onClick={(e) => radioHandler(2)}
                                                                    {...register('approval_status')} />
                                                                <Label className="mb-0 text-danger" for="radioinline2">Decline</Label>
                                                            </div>
                                                        </InputGroup>
                                                        <div className="invalid-feedback">{errors.approval_status ? errors.approval_status.message : ''}</div>
                                                    </FormGroup>
                                                </Col>

                                                {(status === 2) && (
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Amount</Label>
                                                            <input 
                                                                type="number"
                                                                min={0}
                                                                step={0.01}
                                                                {...register('amount')}
                                                                className={`form-control ${errors.amount ? 'is-invalid' : ''}`} />
                                                            <small className="form-text text-muted">Total item(s) price: £{formatAsPrice(totalItemAmount)}.</small>
                                                            <div className="invalid-feedback">{errors.amount ? errors.amount.message : ''}</div>
                                                        </FormGroup>
                                                    </Col>
                                                )}

                                                {(status === 1 || status === 2) && (
                                                    <Col sm="12">
                                                        <Label className="col-form-label">Add Your Comment Below..</Label>
                                                        <SunRichEditor height={200} defaultValue={getValues('note')} onChange={(content) => {
                                                            setValue('note', content);
                                                        }}></SunRichEditor>
                                                        <div className="invalid-feedback">{errors.note ? errors.note.message : ''}</div>
                                                    </Col>
                                                )}
                                            </div>
                                        </CardBody>
                                    </Card>

                                    {(status === 1) && (
                                        <div>
                                            <Image setValue={setValue} getValues={getValues} currentUser={currentUser} trigger={trigger} orderId={id} errors={errors}></Image>                                            
                                        </div>
                                    )}

                                    <div className="mt-2 mb-4 d-flex justify-content-end">
                                        <div className="p-2">
                                            <ButtonLoader type="submit" color="primary" disabled={buttonDisabled} btntext="Save" className="btn btn-primary" />
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </>}
                    </Row>
                </>}
            </Container>
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(VerifyCollabOrder);