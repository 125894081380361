import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { deleteSaleOfferApi, getSaleOffersApi, showSaleOffersApi } from "../../../services/manage-product/saleOfferService";
import { toast } from "react-toastify";
import { filerValues } from "../product/edit/content/settings/map";

export const getSaleOffers = createAsyncThunk('sale-offer/all', async (data, { dispatch }) => {
    const response = await getSaleOffersApi(data);
    dispatch(setTableRowCount(response.response.count));
    dispatch(setSaleOfferStatus(response.response.saleOfferStatus));
    return response.response.data;
});

export const showSaleOffer = createAsyncThunk('sale-offer/show', async (data, { dispatch }) => {
    const response = await showSaleOffersApi(data);
    dispatch(setSaleOffer(response.response.saleoffer));
    return response.response;
});

export const deleteSalesOffer = createAsyncThunk('sale-offer/delete',async(id,{dispatch})=>{
    const response=await deleteSaleOfferApi(id);
    const res=response.response;
    dispatch(getSaleOffers());
    toast.success("Sale offer deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return res;
});

const saleOfferAdapter = createEntityAdapter({});

export const { selectAll: selectSaleOffer, selectById: selectSaleOfferById } = saleOfferAdapter.getSelectors(
    state => state.product.saleOffer
);

const saleOfferSlice = createSlice({
    name: 'saleOffer',
    initialState: saleOfferAdapter.getInitialState({
        searchText: '',
        tableRow: 0,
        filter: filerValues(),
        pagination: {
            count: 1,
            total: 1,
            page_total: 1
        },
        saleOffer: null,
        saleOfferStatus: [],
    }),
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setTableRowCount: (state, action) => {
            state.tableRow = action.payload;
        },
        setSaleOffer: (state, action) => {
            state.saleOffer = action.payload
        },
        setSaleOfferStatus: (state, action) => {
            state.saleOfferStatus = action.payload;
        },
    },
    extraReducers: {
        [getSaleOffers.fulfilled]: saleOfferAdapter.setAll,
    }
});

export const {
    setPagination,
    setTableRowCount,
    setSaleOffer,
    setSaleOfferStatus,
} = saleOfferSlice.actions;

export default saleOfferSlice.reducer;