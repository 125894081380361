import API from "../../utils/api";


export const getSaleOffersApi = async (data) => {
    const response = await API.get(`sale-offers`, {
        params: data,
    });

    return { response };
};

export const showSaleOffersApi = async (id) => {
    const response = await API.get(`sale-offers/${id}/show`);

    return { response };
};

export const deleteSaleOfferApi=async(id)=>{
    const response= await API.delete(`sale-offers/delete/${id}`)
    return{response}
}