import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import * as ReactDOMServer from "react-dom/server";


const GroupReturnJqx = (props) => {
    const {setValue, trigger, getValues} = props;
    let gridRef = useRef(null);
    let selectRef = useRef(null);
    const [orderData, setOrderData] = useState([]);

    // useEffect(() => {
    //     setOrderData(getValues('ordered_products'));
    // }, [getValues('ordered_products')])
    //const roleRegister = register("role", { required: true });
    const datafields = [
        {name: 'id', type: 'number'},
        {name: 'order_line_item_id', type: 'number'},
        {name: 'product_name', type: 'string'},
        {name: 'image', type: 'string'},
        {name: 'sku', type: 'string'},
        {name: 'variant_name', type: 'string'},
        {name: 'quantity', type: 'number'},
        {name: 'return_quantity', type: 'number'},
        {name: 'price', type: 'number'},
        {name: 'total_price', type: 'number'},
        {name: 'is_restock', type: 'string'},
        {name: 'seller_is_declined', type: 'string'},
        {name: 'is_declined', type: 'boolean'},

        {name: 'reason', type: 'string'},

    ];

    const columns = [
        {datafield: 'id', hidden: true},
        {datafield: 'order_line_item_id', hidden: true},
        {
            text: 'Product',
            datafield: 'product_name',
            editable: false,
            columntype: 'text',
            width: '30%',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = getValues('return_items')[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                        {productDetails && <div className="row">
                            <div className="col-md-3">
                                <img className="img-fluid" src={productDetails.image} width="40" alt=""/>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <p className={'font-primary'}>{value}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.variant_name}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.sku}</p>
                                </div>

                            </div>

                        </div>}
                    </div>
                );
                return html;
            },

        },
        {
            text: 'Price',
            datafield: 'price',
            editable: false,
            columntype: 'text',
            width: '10%',
            cellsalign: 'right',
            cellsformat: 'c2',

        },
        {
            text: 'Order Qty',
            datafield: 'quantity',
            editable: false,
            columntype: 'text',
            width: '8%',
            cellsalign: 'right',

        },
        {
            text: 'Quantity',
            datafield: 'return_quantity',
            editable: false,
            columntype: 'text',
            width: '8%',
            cellsalign: 'right',

        },
        {
            text: 'Total Price',
            datafield: 'total_price',
            editable: false,
            columntype: 'text',
            width: '10%',
            cellsalign: 'right',
            cellsformat: 'c2',

        },
        {
            text: 'Reason',
            datafield: 'reason',
            editable: false,
            columntype: 'text',
            width: '15%',

        },
        {
            text: 'Seller Declined',
            datafield: 'seller_is_declined',
            editable: false,
            columntype: 'text',
            width: '7%',
            cellsalign: 'center',

        },
        {
            text: 'Re Stock',
            datafield: 'is_restock',
            editable: false,
            columntype: 'text',
            width: '5%',
            cellsalign: 'center',

        },
        {
            text: 'Declined',
            datafield: 'is_declined',
            editable: true,
            columntype: 'checkbox',
            width: '5%',
            cellsalign: 'center',

        }



    ];
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;
            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };
    const onDelete = (data) => {

        setDataToHookForm();

    }

    const cellEdit = event => {
        const rowArgs = event.args;
        if(rowArgs.datafield == "return_quantity"){
            const singlePrice=gridRef.getcellvalue(rowArgs.rowindex,'price');
            gridRef.setcellvalue(rowArgs.rowindex, "total_price", rowArgs.value*singlePrice);
        }
        gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
        setDataToHookForm();
    };
    const setDataToHookForm = () => {
        let orderData = [];


        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            orderData.push(rowData);

        }
        setValue('return_items', orderData);
        trigger('return_items');

    }



    return (
        <Fragment>

            <div style={{width: '100%'}}>
                {getValues('return_items').length > 0 && <JqxTable
                    selectionmode={'checkbox'}
                    rowsheight={75}
                    datafields={datafields}
                    columns={columns}
                    data={getValues('return_items')}
                    myGrid={gridRef}
                    getJqxRef={getJqxRef}
                    onCellendedit={cellEdit}
                    disableCreateRow
                    onRowselect={(event)=>{
                        setValue('selected_items',gridRef.getselectedrowindexes());
                        trigger('selected_items')
                   }}
                    onRowunselect={()=>{
                        setValue('selected_items',gridRef.getselectedrowindexes());
                        trigger('selected_items')
                    }}
                />}
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.return_items) == JSON.stringify(nextProps.return_items);
}

export default GroupReturnJqx;