import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from "../../../layout/breadcrumb";
import { Badge, Card, CardBody, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { Accordion } from "react-bootstrap";
import "../../../assets/scss/components/accordian.scss";
import AsyncSelect from "react-select/async";
import { mapSearchAutoSuggestion } from "../../../utils/mapper";
import { sellerAutoSuggestionApi, sellerBrandSuggestionApi } from "../../../services/manage-product/productService";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import withReducer from "../../../store/withReducer";
import productReducer from "../store";
import { deleteSalesOffer, getSaleOffers, selectSaleOffer } from "../store/saleOfferSlice";
import ReactPaginate from "react-paginate";
import PreLoader from "../../../share-components/ui/preLoader";
import Select from "react-select";
import { toast } from 'react-toastify';
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { mapSaleOfferResponseToTable } from "../../../utils/mapper";
import {useHistory} from "react-router-dom";

const dataMenu = [
    {
        type: 0,
        text: 'Sale Offers',
    }
];

let tableColumns = [
    {
        name: 'Title',
        selector: row => row.sale_title,
        sortable: true,
        center: false,
    },
    {
        name: 'Start Date',
        selector: row => row.start_date,
        sortable: true,
        center: false,
    },
    {
        name: 'End Date',
        selector: row => row.end_date,
        sortable: true,
        center: false,
    },
    {
        name: 'Status',
        selector: row => row.status,
        cell: (row) => (
            <Badge color={getStatusBadgeVariant(row.status)}>
              {row.status}
            </Badge>
          ),
        sortable: true,
        center: false,
    },
    {
        name: 'No. Products',
        selector: row => row.no_products,
        sortable: true,
        center: true,
    },
    {
        name: 'Created At',
        selector: row => row.created_at,
        sortable: true,
        center: false,
    },
];

const getStatusBadgeVariant = (status) => {
    switch (status) {
      case 'Active':
        return 'success';
      case 'Expired':
        return 'danger';
      case 'Scheduled':
        return 'warning';
      default:
        return '';
    }
};

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

function SaleOffer() {
    const [page, setPage] = useState(1);
    const [searchStatus, setSearchStatus] = useState('');
    const [searchName, setSearchName] = useState('');
    const [sort, setSort] = useState('id-desc');
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [saleOffers, setSaleOffers] = useState([]);
    const saleOfferState = useSelector(({saleOffer}) => saleOffer.saleOffer);
    const saleOfferData = useSelector(selectSaleOffer);
    const saleOfferStatus = useSelector(({saleOffer}) => saleOffer.saleOffer.saleOfferStatus);
    
    let history = useHistory();

    useEffect(() => {
        setSaleOffers(mapSaleOfferResponseToTable(saleOfferData));
    }, [saleOfferData]);

    const getData = () => {
        dispatch(getSaleOffers({
            pageSize: 10,
            page: page,
            name: searchName,
            status: searchStatus,
            sort: sort,
        })).then(res => {
            if (!res.error) setLoading(false);
        })
    };

    useEffect(() => {
        getData();
    }, [page, searchName, searchStatus]);

    const onDelete=(row)=>{
        dispatch(deleteSalesOffer(row.id))
    }

    const onView=(row)=>{
        history.push(`/product/sale-offers/${row.id}/view`);
    }    

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="Sale Offers" data={dataMenu} />
                <Container fluid={true} className="product-wrapper">
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col md="">
                                    <Select
                                        className="select2-filter"
                                        isClearable
                                        cacheOptions
                                        styles={customStyles} 
                                        placeholder="Select status"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        onChange={(e) => {
                                            setSearchStatus(e ? e.id : null);
                                        }}
                                        options={saleOfferStatus}
                                    />
                                </Col>
                                <Col md="">
                                    <Form>
                                        <FormGroup className="m-0">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder="Search title"
                                                onChange={(e) => {
                                                    setSearchName(e.target.value);
                                                }}
                                            />
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={saleOffers}
                                            paginationServer
                                            paginationTotalRows={saleOfferState.tableRow}
                                            paginationPerPage={10}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => setPage(page)}
                                            onView={onView}
                                            onDelete={onDelete}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Container>
            </>
            }
        </Fragment>
    );
}

export default withReducer([{ saleOffer: productReducer }])(SaleOffer);
