import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";
import { useParams } from "react-router-dom";
import { getCollabOrder } from "../store/orderSlice";
import orderReducer from "../store";
import { ViewContent } from "./verify-collab-order/viewContent";
import PreLoader from "../../../share-components/ui/preLoader";


const dataMenu = [
    {
        type: 1,
        text: 'Collab Orders',
        link: '/order/collab-orders'
    },
    {
        type: 0,
        text: "Collab Order Details"
    }
];

const ViewCollabOrder = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const collabOrderState = useSelector(({ order }) => order.order);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if (id) {
            dispatch(getCollabOrder(id)).then(res => {
                setIsLoading(false);
            });
        }

    }, [dispatch, id]);



    return (
        <Fragment>
            <Breadcrumb title="Collab Order Details" data={dataMenu} />
            <Container fluid={true}>
                {isLoading ? <>
                    <PreLoader></PreLoader>
                </> : <>
                    <Row>
                        <Col sm="12">
                            <ViewContent collabOrderState={collabOrderState} orderId={id} />
                        </Col>
                    </Row>
                </>}
            </Container>
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(ViewCollabOrder);