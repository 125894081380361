import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import { getUsOrderApi, reCreateUSOrderApi } from '../../../services/usOrder';

export const getUsOrder = createAsyncThunk('order/usOrder',async(data, {dispatch})=>{
    const response = await getUsOrderApi(data);
    dispatch(setUSOrderTableRowCount(response.response.count))
    return response.response.data;
});

export const reCreateUSOrder = createAsyncThunk('order/reCreateUSOrder',async(data, {dispatch})=>{
    const response = await reCreateUSOrderApi(data);
    return response.response;
});

const usOrderAdapter = createEntityAdapter({});
export const {selectAll: selectUsOrders, selectById: usOrderById} = usOrderAdapter.getSelectors(
    state => state.order.usOrder
);

const usOrderSlice = createSlice({
    name: 'usOrder',
    initialState: usOrderAdapter.getInitialState({
        usOrderTableRow: 0, 
    }),
    reducers: {
        setUSOrderTableRowCount: (state, action) => {
            state.usOrderTableRow = action.payload;
        },
    },
    extraReducers: {
        [getUsOrder.fulfilled]: usOrderAdapter.setAll,
    }
});

export const {setUSOrderTableRowCount} = usOrderSlice.actions;

export default usOrderSlice.reducer;